import * as React from "react";
import { AutoScalingDoc } from "./autoScalingDoc";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { NGInputAdapter } from "../../newcomponents/input/inputAdapter";
import { NGLabel } from "../../newcomponents/text/label";
import { NGLabelText } from "../../newcomponents/text/labelText";
import { NGSelect } from "../../newcomponents/select/ngselect";
import { NGSwitch } from "../../newcomponents/switch";
import { observer } from "mobx-react-lite";
import { WorkloadDraftMobx } from "../../mst/stores/workload.draft";
import NGAlert from "../../newcomponents/alert";
import { InfoTooltip } from "../../components/InfoTooltip";

interface Props {
  draft: WorkloadDraftMobx;
}
const DefaultOptionsRaw: React.FC<Props> = ({ draft }) => {
  const defaultOptions = draft.defaultOptions;

  return (
    <>
      {!draft._hasDefaultOptions ? (
        <NGSwitch
          data-testid="switch-override-default-options"
          className="mb-4"
          onChange={(value) => draft.setOverrideDefaultOptions(value)}
          value={draft.overrideDefaultOptions}
        >
          <NGLabelText>Override Default Options</NGLabelText>
        </NGSwitch>
      ) : null}
      {draft._hasDefaultOptions || (!draft._hasDefaultOptions && draft.overrideDefaultOptions) ? (
        <>
          {draft.isStateful || draft.isCron ? null : (
            <>
              <div className="mb-4 flex items-center">
                <NGSwitch
                  data-testid="switch-capacity-ai"
                  onChange={(value) => draft.setCapacityAI(value)}
                  value={draft.capacityAI}
                  isDisabled={["cpu", "multi"].includes(draft.defaultOptions.metric.value)}
                >
                  <NGLabelText>Capacity AI</NGLabelText>
                </NGSwitch>
                <InfoTooltip
                  title={[
                    `Enables intelligent dynamic resource allocation of CPU and Memory for each container by setting resource reservations based on usage data realtime observation.`,
                    `Capacity AI can significantly reduce cost. It is well suited for workloads with steady usage characteristics and less ideally suited for workloads with significant spikes in usage.`,
                  ]}
                />
              </div>
              {["cpu", "multi"].includes(draft.defaultOptions.metric.value) ? (
                <NGAlert
                  className="mb-4"
                  message={`Capacity AI is not available when Scaling Strategy is set to ${
                    draft.defaultOptions.metric.value === "cpu" ? "CPU Utilization" : "Multi"
                  }`}
                />
              ) : null}
            </>
          )}
          {draft.isCron ? null : (
            <div className="mb-4 flex items-center">
              <NGSwitch value={defaultOptions.debug} onChange={defaultOptions.setDebug}>
                <NGLabelText>Debug</NGLabelText>
              </NGSwitch>
              <InfoTooltip
                title={`Adds debug response headers when the headers "x-cpln-debug: true" is in the request.`}
              />
            </div>
          )}
          <div className="mb-4 flex items-center">
            <NGSwitch value={defaultOptions.suspend} onChange={defaultOptions.setSuspend}>
              <NGLabelText>Suspend</NGLabelText>
            </NGSwitch>
            <InfoTooltip title="If checked, the workload will be in a suspended mode and not running." />
          </div>
          {draft.isCron ? null : (
            <NGFormElement
              required
              name="timeoutSeconds"
              label={defaultOptions.timeoutSeconds.label}
              value={defaultOptions.timeoutSeconds.value}
              onChange={defaultOptions.timeoutSeconds.setValue}
              info={[
                `The maximum request duration in seconds before the workload will timeout.`,
                `This timeout amount can be reached when waiting for the workload to respond or when waiting for a new workload to become available when using Autoscaling.`,
                `The minimum value is 1 second and the maximum value is 3600 seconds (1 hour).`,
              ]}
              description={"Request to the workload will timeout when reaching this number of seconds."}
            />
          )}
          {draft.isServerless || draft.isStandard || draft.isStateful ? (
            <>
              {!draft.defaultOptions._hasAutoscaling ? (
                <NGSwitch
                  data-testid="switch-override-autoscaling"
                  onChange={(value) => draft.defaultOptions.setOverrideAutoscaling(value)}
                  value={draft.defaultOptions.overrideAutoscaling}
                >
                  <NGLabelText>Override Autoscaling</NGLabelText>
                </NGSwitch>
              ) : null}
              {draft.defaultOptions._hasAutoscaling ||
              (!draft.defaultOptions._hasAutoscaling && draft.defaultOptions.overrideAutoscaling) ? (
                <>
                  <div className="mt-4 mb-2 flex items-center gap-2">
                    <NGLabel>Auto Scaling</NGLabel>
                    <AutoScalingDoc />
                  </div>
                  <NGLabel>{draft.defaultOptions.metric.label}</NGLabel>
                  <div className="flex mb-4">
                    <NGSelect
                      style={{ width: 450 }}
                      data-testid="scaling-strategy"
                      onChange={(value) => draft.setMetric(value as any, "default")}
                      options={draft.defaultOptions.metric.options}
                      value={draft.defaultOptions.metric.value}
                    />
                    <InfoTooltip
                      className={`self-center`}
                      // TODO update for workload types other than serverless
                      title={[
                        `Scaling is determined by a specific metric, with the target being a replica.`,
                        `‘Disabled’ means there’s only one ‘Replica count’. In the API, the Min Scale and Max Scale are both set to the same specified value.`,
                        `'concurrency (Concurrent Requests)' uses the number of concurrent requests to the workload.`,
                        `'cpu (CPU Utilization)' uses % processor time utilized by the workload.`,
                        `'memory' memory in % for the target.`,
                        `'rps (Requests Per Second)' uses requests per second for the target.`,
                        `'Multi' allows you to scale with both CPU and Memory.`,
                        `The number of replicas for this deployment scales up/down in accordance with the chosen scaling strategy and values provided.`,
                      ]}
                    />
                  </div>
                  {defaultOptions.metric.value === "disabled" ? null : (
                    <>
                      {draft.defaultOptions.metric.value === "latency" ? (
                        <>
                          <div className="mb-4 flex items-center">
                            <div>
                              <NGLabel>{draft.defaultOptions.metricPercentile.label}</NGLabel>
                              <NGSelect
                                style={{ width: 215, marginRight: 10 }}
                                onChange={draft.defaultOptions.metricPercentile.setValue}
                                options={draft.defaultOptions.metricPercentile.options}
                                value={draft.defaultOptions.metricPercentile.value}
                              />
                            </div>
                            <div>
                              <NGInputAdapter data={defaultOptions.target} style={{ width: 225 }} />
                            </div>
                          </div>
                        </>
                      ) : draft.defaultOptions.metric.value === "multi" ? (
                        <>
                          <div className="mb-4 flex items-center">
                            <div>
                              <NGInputAdapter
                                data={defaultOptions.multiCpuTarget}
                                style={{ width: 215, marginRight: 10 }}
                              />
                            </div>
                            <div>
                              <NGInputAdapter
                                data={defaultOptions.multiMemoryTarget}
                                style={{ width: 215, marginLeft: 10 }}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>
                          <NGFormElement
                            required
                            name="target"
                            label={defaultOptions.target.label}
                            value={defaultOptions.target.value}
                            onChange={defaultOptions.target.setValue}
                          />
                        </div>
                      )}
                    </>
                  )}
                  <NGFormElement
                    required
                    name="minScale"
                    label={defaultOptions.minScale.label}
                    value={defaultOptions.minScale.value}
                    onChange={defaultOptions.minScale.setValue}
                    info={[
                      `The minimum number of replicas.`,
                      `If set to zero, a new replica becomes active when a request is received, after a few seconds. It is recommended to set Min Scale to at least 2 replicas for production use cases when availability is paramount.`,
                    ]}
                    description="Minimum number of replicas."
                  />
                  {defaultOptions.metric.value === "disabled" ? null : (
                    <NGFormElement
                      required
                      name="maxScale"
                      label={defaultOptions.maxScale.label}
                      value={defaultOptions.maxScale.value}
                      onChange={defaultOptions.maxScale.setValue}
                      info={["The maximum number of replicas."]}
                      description="The maximum number of replicas."
                    />
                  )}
                  {defaultOptions.metric.value === "disabled" ? null : draft.isServerless || draft.isStandard ? (
                    <NGFormElement
                      required
                      name="scaleToZeroDelay"
                      label={defaultOptions.scaleToZeroDelay.label}
                      value={defaultOptions.scaleToZeroDelay.value}
                      onChange={defaultOptions.scaleToZeroDelay.setValue}
                      info={[
                        `The number of seconds until a scale down event occurs. For example, if the number of concurrent requests drops to a level that warrants a scale down event, the number of replicas will be adjusted after the number of seconds specified by this value.`,
                        `The minimum value is 30 seconds.`,
                      ]}
                      description="Number of seconds (min. 30) before a scale down event."
                    />
                  ) : null}
                  {draft.isServerless ? (
                    <NGFormElement
                      required
                      name="maxConcurrency"
                      label={defaultOptions.maxConcurrency.label}
                      value={defaultOptions.maxConcurrency.value}
                      onChange={defaultOptions.maxConcurrency.setValue}
                      info={[
                        `Maximum number of concurrent requests routed to a replica.`,
                        `If no replicas are available to fulfill the request, the request is queued until a replica is available. Either from existing requests having been completed, or from new replica(s) becoming available as a result of a scale up action.`,
                      ]}
                      description="Maximum number of concurrent requests routed to a replica."
                    />
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export const DefaultOptions = observer(DefaultOptionsRaw);
