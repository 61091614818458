import { types, Instance, destroy as mobxDestroy, getSnapshot, flow } from "mobx-state-tree";
import {
  WorkloadMobx,
  HealthCheckSpecMobx,
  ContainerSpecMobx,
  DefaultOptionsMobx,
  LocalOptionsMobx,
  ContainerMetricsMobx,
  JobSpecMobx,
} from "../kinds/workload";
import { MemoryModel } from "../../mobxDataModels/memoryModel";
import { CpuModel } from "../../mobxDataModels/cpuModel";
import { WorkloadDraftFirewallModel } from "./workload.draft.firewall";
import { WorkloadDraftContainerModel, WorkloadDraftContainerMobx } from "./workload.draft.container";
import { WorkloadDraftOptionsMobx, WorkloadDraftOptionsModel } from "./workload.draft.options";
import { WorkloadDraftContainerProbeModel } from "./workload.draft.container.probe";
import { StringModel } from "../../mobxDataModels/stringModel";
import { NameModel } from "../../mobxDataModels/nameModel";
import { defaultValues } from "../base";
import { linksOf, parentLink, request, resourceLink } from "../../services/cpln";
import { VOLUME_URI, VolumesModel } from "./workload.draft.container.volume";
import { WorkloadDraftContainerMetricsModel } from "./workload.draft.container.metrics";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { TagsNewModel } from "../../mobxDataModels/tagsNewModel";
import { WorkloadDraftJobMobx, WorkloadDraftJobModel } from "./workload.draft.job";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { WorkloadDraftRolloutOptionsModel } from "./workload.draft.rolloutOptions";
import { EnvVarsModel } from "../../mobxDataModels/envVarsModel";
import { kindMobxToTagsModel } from "../mobxUtilts";
import { WorkloadDraftContainerGPUModel } from "./workload.draft.container.gpu";
import { WorkloadDraftSecurityOptionsModel } from "./workload.draft.securityOptions";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { Secret } from "../../schema/types/secret";
import { Policy } from "../../schema/types/policy";
import { WorkloadDraftLoadBalancerModel } from "./workload.draft.lb";
import { WorkloadDraftLoadBalancerDirectModel } from "./workload.draft.lb.direct";
import { DIRECT_LB_ENABLED } from "../../envVariables";

export const WorkloadDraftModel = types
  .model({
    _new: types.optional(types.boolean, false),
    _version: types.optional(types.number, 1),
    _name: types.optional(types.string, ""),
    _hasDefaultOptions: types.optional(types.boolean, true),
    overrideDefaultOptions: types.optional(types.boolean, false),
    _description: types.optional(types.string, ""),
    _capacityAI: types.optional(types.boolean, defaultValues.capacityAI),
    _type: types.optional(types.string, "serverless"),
    _gvcEnv: types.frozen(),
    _supportDynamicTags: types.optional(types.boolean, false),
    sidecar: types.maybe(
      types.model({
        envoy: types.maybe(types.frozen()),
      }),
    ),
    name: types.optional(StringModel, () => NameModel.create()),
    description: types.optional(StringModel, () =>
      StringModel.create({
        label: "Description",
      }),
    ),
    type: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Type",
        initialValue: "serverless",
        options: [
          { label: "Serverless", value: "serverless" },
          { label: "Standard", value: "standard" },
          { label: "Cron", value: "cron" },
          { label: "Stateful", value: "stateful" },
        ],
      }),
    ),
    capacityAI: types.optional(types.boolean, defaultValues.capacityAI),
    container1: types.optional(WorkloadDraftContainerModel, () =>
      WorkloadDraftContainerModel.create({
        _servesTraffic: true,
        _ports: [
          {
            protocol: defaultValues.workload.container.port.protocol,
            number: defaultValues.workload.container.port.number,
          },
        ],
      }),
    ),
    container2: types.maybe(WorkloadDraftContainerModel),
    container3: types.maybe(WorkloadDraftContainerModel),
    container4: types.maybe(WorkloadDraftContainerModel),
    container5: types.maybe(WorkloadDraftContainerModel),
    container6: types.maybe(WorkloadDraftContainerModel),
    container7: types.maybe(WorkloadDraftContainerModel),
    container8: types.maybe(WorkloadDraftContainerModel),
    newContainer: types.optional(WorkloadDraftContainerModel, () => WorkloadDraftContainerModel.create()),
    defaultOptions: types.optional(WorkloadDraftOptionsModel, () =>
      WorkloadDraftOptionsModel.create({ isLocal: false, isNew: false }),
    ),
    deletedLocalOption: types.optional(types.boolean, false),
    _localOptions: types.array(WorkloadDraftOptionsModel),
    localOptions: types.array(WorkloadDraftOptionsModel),
    rolloutOptions: types.optional(WorkloadDraftRolloutOptionsModel, () => WorkloadDraftRolloutOptionsModel.create()),
    securityOptions: types.optional(WorkloadDraftSecurityOptionsModel, () =>
      WorkloadDraftSecurityOptionsModel.create(),
    ),
    firewall: types.optional(WorkloadDraftFirewallModel, () =>
      WorkloadDraftFirewallModel.create({ _gvcName: "notset", _orgName: "notset", _workloadName: "" }),
    ),
    job: types.optional(WorkloadDraftJobModel, () => WorkloadDraftJobModel.create()),
    supportDynamicTags: types.optional(types.boolean, false),
    loadBalancer: types.optional(WorkloadDraftLoadBalancerModel, () => WorkloadDraftLoadBalancerModel.create()),
    _identityLink: types.optional(types.string, ""),
    identityLink: types.optional(types.string, ""),
    tags: types.optional(TagsNewModel, () => TagsNewModel.create()),
    processingIdentity: types.optional(types.boolean, false),
    validIdentityLinks: types.array(types.string),
    invalidIdentityLinks: types.array(types.string),
  })
  .views((self) => ({
    get allContainers() {
      let containers: WorkloadDraftContainerMobx[] = [self.container1];
      if (self.container2) {
        containers.push(self.container2);
      }
      if (self.container3) {
        containers.push(self.container3);
      }
      if (self.container4) {
        containers.push(self.container4);
      }
      if (self.container5) {
        containers.push(self.container5);
      }
      if (self.container6) {
        containers.push(self.container6);
      }
      if (self.container7) {
        containers.push(self.container7);
      }
      if (self.container8) {
        containers.push(self.container8);
      }
      return containers;
    },
  }))
  .views((self) => ({
    get hasAnyCloudVolume() {
      let res = false;
      for (const c of self.allContainers) {
        for (const v of c.volumes.editVolumes) {
          if (v.uri.startsWith("scratch://")) {
            continue;
          }
          if (v.uri.startsWith("cpln://")) {
            continue;
          }
          res = true;
          break;
        }
        if (res) {
          break;
        }
      }
      return res;
    },
    get metricPorts() {
      let ports: number[] = [];
      for (let c of self.allContainers) {
        if (c.metrics.useMetrics) {
          if (!ports.includes(c.metrics.asObject.port)) {
            ports.push(c.metrics.asObject.port);
          }
        }
      }
      return ports;
    },
    get allExposedPorts() {
      let ports: number[] = [];
      for (let c of self.allContainers) {
        for (let p of c.ports) {
          if (!ports.includes(p.asObject.number)) {
            ports.push(p.asObject.number);
          }
        }
      }
      return ports;
    },
    get allScratchVolumes() {
      const scratchVolumes: string[] = [];

      for (const volume of self.container1.volumes.editVolumes) {
        if (volume.uriPrefix !== VOLUME_URI.SCRATCH.prefix) {
          continue;
        }
        if (!scratchVolumes.includes(volume.uriValue)) {
          scratchVolumes.push(volume.uriValue);
        }
      }

      if (self.container2) {
        for (const volume of self.container2.volumes.editVolumes) {
          if (volume.uriPrefix !== VOLUME_URI.SCRATCH.prefix) {
            continue;
          }
          if (!scratchVolumes.includes(volume.uriValue)) {
            scratchVolumes.push(volume.uriValue);
          }
        }
      }

      if (self.container3) {
        for (const volume of self.container3.volumes.editVolumes) {
          if (volume.uriPrefix !== VOLUME_URI.SCRATCH.prefix) {
            continue;
          }
          if (!scratchVolumes.includes(volume.uriValue)) {
            scratchVolumes.push(volume.uriValue);
          }
        }
      }

      if (self.container4) {
        for (const volume of self.container4.volumes.editVolumes) {
          if (volume.uriPrefix !== VOLUME_URI.SCRATCH.prefix) {
            continue;
          }
          if (!scratchVolumes.includes(volume.uriValue)) {
            scratchVolumes.push(volume.uriValue);
          }
        }
      }

      if (self.container5) {
        for (const volume of self.container5.volumes.editVolumes) {
          if (volume.uriPrefix !== VOLUME_URI.SCRATCH.prefix) {
            continue;
          }
          if (!scratchVolumes.includes(volume.uriValue)) {
            scratchVolumes.push(volume.uriValue);
          }
        }
      }

      if (self.container6) {
        for (const volume of self.container6.volumes.editVolumes) {
          if (volume.uriPrefix !== VOLUME_URI.SCRATCH.prefix) {
            continue;
          }
          if (!scratchVolumes.includes(volume.uriValue)) {
            scratchVolumes.push(volume.uriValue);
          }
        }
      }

      if (self.container7) {
        for (const volume of self.container7.volumes.editVolumes) {
          if (volume.uriPrefix !== VOLUME_URI.SCRATCH.prefix) {
            continue;
          }
          if (!scratchVolumes.includes(volume.uriValue)) {
            scratchVolumes.push(volume.uriValue);
          }
        }
      }

      if (self.container8) {
        for (const volume of self.container8.volumes.editVolumes) {
          if (volume.uriPrefix !== VOLUME_URI.SCRATCH.prefix) {
            continue;
          }
          if (!scratchVolumes.includes(volume.uriValue)) {
            scratchVolumes.push(volume.uriValue);
          }
        }
      }

      return scratchVolumes;
    },
    get isStandard() {
      return self.type.value === "standard";
    },
    get isServerless() {
      return self.type.value === "serverless";
    },
    get isCron() {
      return self.type.value === "cron";
    },
    get isStateful() {
      return self.type.value === "stateful";
    },
    get isServingTraffic() {
      if (self.container1.servesTraffic) {
        return true;
      }
      if (self.container2?.servesTraffic) {
        return true;
      }
      if (self.container3?.servesTraffic) {
        return true;
      }
      if (self.container4?.servesTraffic) {
        return true;
      }
      if (self.container5?.servesTraffic) {
        return true;
      }
      if (self.container6?.servesTraffic) {
        return true;
      }
      if (self.container7?.servesTraffic) {
        return true;
      }
      if (self.container8?.servesTraffic) {
        return true;
      }
      return false;
    },
    get isInboundPublic() {
      return self.firewall.external_allInboundAllowed;
    },
  }))
  .actions((self) => ({
    setIdentityLink(value: string) {
      self.identityLink = value;
    },
    setOverrideDefaultOptions(value: boolean) {
      self.overrideDefaultOptions = value;
    },
    setCapacityAI(value: boolean) {
      self.capacityAI = value;
      self.defaultOptions.setCapacityAI(value, true);
      self.container1.setCapacityAI(value);
      self.container2?.setCapacityAI(value);
      self.container3?.setCapacityAI(value);
      self.container4?.setCapacityAI(value);
      self.container5?.setCapacityAI(value);
      self.container6?.setCapacityAI(value);
      self.container7?.setCapacityAI(value);
      self.container8?.setCapacityAI(value);
      self.newContainer.setCapacityAI(value);
    },
    setSupportDynamicTags(value: boolean) {
      self.supportDynamicTags = value;
    },
  }))
  .actions((self) => ({
    ensureServerlessContainerServingTrafficHasPort() {
      if (self.type.value !== "serverless") {
        return;
      }

      if (self.container1.servesTraffic && self.container1.ports.length < 1) {
        self.container1.addPort();
      }
      if (self.container2 && self.container2.servesTraffic && self.container2.ports.length < 1) {
        self.container2.addPort();
      }
      if (self.container3 && self.container3.servesTraffic && self.container3.ports.length < 1) {
        self.container3.addPort();
      }
      if (self.container4 && self.container4.servesTraffic && self.container4.ports.length < 1) {
        self.container4.addPort();
      }
      if (self.container5 && self.container5.servesTraffic && self.container5.ports.length < 1) {
        self.container5.addPort();
      }
      if (self.container6 && self.container6.servesTraffic && self.container6.ports.length < 1) {
        self.container6.addPort();
      }
      if (self.container7 && self.container7.servesTraffic && self.container7.ports.length < 1) {
        self.container7.addPort();
      }
      if (self.container8 && self.container8.servesTraffic && self.container8.ports.length < 1) {
        self.container8.addPort();
      }
    },
  }))
  .actions((self) => ({
    setMetric(
      value: "concurrency" | "rps" | "cpu" | "memory" | "latency" | "disabled" | "multi",
      typeOrIndex: "default" | number,
    ) {
      if (typeOrIndex === "default") {
        self.setCapacityAI(!["cpu", "multi"].includes(value));
        self.defaultOptions.metric.setValue(value);
      } else {
        self.localOptions[typeOrIndex].setCapacityAI(!["cpu", "multi"].includes(value), false);
        self.localOptions[typeOrIndex].metric.setValue(value);
      }
    },
  }))
  .actions((self) => ({
    setMetricOptions() {
      let options: { label: string; value: string }[] = [];
      if (self.isStandard || self.isStateful) {
        options = [
          { label: "Disabled", value: "disabled" },
          { label: "CPU Utilization", value: "cpu" },
          { label: "Memory (%)", value: "memory" },
          { label: "Requests Per Second", value: "rps" },
          { label: "Latency", value: "latency" },
          { label: "Multi (CPU & Memory)", value: "multi" },
        ];
      }

      if (self.isServerless) {
        options = [
          { label: "Disabled", value: "disabled" },
          { label: "CPU Utilization", value: "cpu" },
          { label: "Memory (%)", value: "memory" },
          { label: "Requests Per Second", value: "rps" },
          { label: "Concurrent Requests", value: "concurrency" },
        ];
      }

      self.defaultOptions.metric.setOptions(options);

      for (let localOptions of self.localOptions) {
        localOptions.metric.setOptions(options);
      }
    },

    setMetricValues() {
      const defaultMetricValue = getDefaultMetricValueOfWorkloadType(self.type.value);

      self.setMetric(defaultMetricValue, "default");
      for (let index in self.localOptions) {
        self.setMetric(defaultMetricValue, Number(index));
      }
    },
  }))
  .actions((self) => ({
    setType(value: string) {
      self.type.setValue(value);
      self.setMetricOptions();
      self.setMetricValues();

      self.ensureServerlessContainerServingTrafficHasPort();
    },
  }))
  .views((self) => ({
    containerByName(containerName: string): undefined | WorkloadDraftContainerMobx {
      if (self.container1.name.value === containerName) {
        return self.container1;
      } else if (self.container2?.name.value === containerName) {
        return self.container2;
      } else if (self.container3?.name.value === containerName) {
        return self.container3;
      } else if (self.container4?.name.value === containerName) {
        return self.container4;
      } else if (self.container5?.name.value === containerName) {
        return self.container5;
      } else if (self.container6?.name.value === containerName) {
        return self.container6;
      } else if (self.container7?.name.value === containerName) {
        return self.container7;
      } else if (self.container8?.name.value === containerName) {
        return self.container8;
      }
      return;
    },
    containerByOrderNumber(orderNumber: number): WorkloadDraftContainerMobx | undefined {
      switch (orderNumber) {
        case 1:
          return self.container1;
        case 2:
          return self.container2;
        case 3:
          return self.container3;
        case 4:
          return self.container4;
        case 5:
          return self.container5;
        case 6:
          return self.container6;
        case 7:
          return self.container7;
        case 8:
          return self.container8;
        default:
          return;
      }
    },
    containersOtherThanByName(containerName: string): WorkloadDraftContainerMobx[] {
      let res: WorkloadDraftContainerMobx[] = [];
      if (self.container1.name.value !== containerName) {
        res.push(self.container1);
      }
      if (self.container2 && self.container2.name.value !== containerName) {
        res.push(self.container2);
      }
      if (self.container3 && self.container3.name.value !== containerName) {
        res.push(self.container3);
      }
      if (self.container4 && self.container4.name.value !== containerName) {
        res.push(self.container4);
      }
      if (self.container5 && self.container5.name.value !== containerName) {
        res.push(self.container5);
      }
      if (self.container6 && self.container6.name.value !== containerName) {
        res.push(self.container6);
      }
      if (self.container7 && self.container7.name.value !== containerName) {
        res.push(self.container7);
      }
      if (self.container8 && self.container8.name.value !== containerName) {
        res.push(self.container8);
      }
      return res;
    },
    containerNamesExcept(containerDraft: WorkloadDraftContainerMobx) {
      let res: string[] = [];
      if (self.container1 !== containerDraft) {
        res.push(self.container1.name.value);
      }
      if (self.container2 && self.container2 !== containerDraft) {
        res.push(self.container2.name.value);
      }
      if (self.container3 && self.container3 !== containerDraft) {
        res.push(self.container3.name.value);
      }
      if (self.container4 && self.container4 !== containerDraft) {
        res.push(self.container4.name.value);
      }
      if (self.container5 && self.container5 !== containerDraft) {
        res.push(self.container5.name.value);
      }
      if (self.container6 && self.container6 !== containerDraft) {
        res.push(self.container6.name.value);
      }
      if (self.container7 && self.container7 !== containerDraft) {
        res.push(self.container7.name.value);
      }
      if (self.container8 && self.container8 !== containerDraft) {
        res.push(self.container8.name.value);
      }
      return res;
    },
  }))
  .actions((self) => ({
    generalReset() {
      self.name.setInitialValue(self._name);
      self.description.setInitialValue(self._description);
      self.type.setInitialValue(self._type);
      self.supportDynamicTags = self._supportDynamicTags;
    },
    defaultOptionsReset() {
      self.setCapacityAI(self._capacityAI);
      self.setOverrideDefaultOptions(false);
      self.defaultOptions.reset();
    },
    localOptionsReset() {
      self.localOptions.clear();
      for (let localOptionsItem of self._localOptions) {
        self.localOptions.push(JSON.parse(JSON.stringify(localOptionsItem)));
      }
      self.deletedLocalOption = false;
      self.setMetricOptions();
    },
  }))
  .actions((self) => ({
    reset() {
      self.generalReset();
      self.setMetricOptions();
      self.setMetricValues();
      self.container1.reset();
      self.container2?.reset();
      self.container3?.reset();
      self.container4?.reset();
      self.container5?.reset();
      self.container6?.reset();
      self.container7?.reset();
      self.container8?.reset();
      self.newContainer.reset();
      self.newContainer.setCapacityAI(self._capacityAI);
      self.identityLink = self._identityLink;
      self.defaultOptionsReset();
      self.localOptionsReset();
      self.rolloutOptions.reset();
      self.securityOptions.reset();
      self.firewall.reset();
      self.tags.reset();
      self.loadBalancer.reset();

      // for when defaultOptions is missing and we set the default value manually
      if (self.defaultOptions.metric.value === "cpu") {
        self._capacityAI = false;
        self.setCapacityAI(false);
      }
    },
    removeLocalOptionsItemAt(index: number) {
      const item = self.localOptions[index];
      if (!item.isNew) {
        self.deletedLocalOption = true;
      }
      self.localOptions.remove(item);
    },
    defaultOptionsConfirm() {
      self.defaultOptions._confirm();
      self._capacityAI = self.capacityAI;
      for (let localOptionsItem of self.localOptions) {
        localOptionsItem.defaultOptionsUpdated(self.defaultOptions.asObject);
      }
    },
    localOptionsConfirm() {
      for (let localOptionsItem of self.localOptions) {
        localOptionsItem._confirm();
      }
      self._localOptions.clear();
      for (let localOptionsItem of self.localOptions) {
        self._localOptions.push(JSON.parse(JSON.stringify(localOptionsItem)));
      }
      self.deletedLocalOption = false;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.container1.manualAfterAttach();
      self.container2?.manualAfterAttach();
      self.container3?.manualAfterAttach();
      self.container4?.manualAfterAttach();
      self.container5?.manualAfterAttach();
      self.container6?.manualAfterAttach();
      self.container7?.manualAfterAttach();
      self.container8?.manualAfterAttach();
      self.newContainer?.manualAfterAttach();
      self.reset();
    },
    setGeneralName(value: string) {
      self.name.setValue(value);
      self.firewall.setWorkloadName(value);
    },
    setContainerName(containerDraft: WorkloadDraftContainerMobx, value: string) {
      containerDraft.name.setValue(value);
      containerDraft.setIsNameManuallyChanged(true);
      const nameToCheck = containerDraft.name.value;
      const names = self.containerNamesExcept(containerDraft);
      if (names.includes(nameToCheck)) {
        containerDraft.name.setOverrideError("Name is not unique.");
      } else {
        containerDraft.name.clearOverrideError();
      }
    },
    setContainerImage(containerDraft: WorkloadDraftContainerMobx, value: string) {
      let calculatedName = value.split("/").pop()!.split("@").shift()!.split(":").shift()!.toLowerCase();
      if (calculatedName.startsWith("cpln-")) {
        calculatedName = calculatedName.replace("cpln-", "");
      }
      containerDraft.image.setValue(value);
      if (!containerDraft.isNameManuallyChanged) {
        containerDraft.name.setValue(calculatedName);
      }
      const nameToCheck = containerDraft.name.value;
      const names = self.containerNamesExcept(containerDraft);
      if (names.includes(nameToCheck)) {
        containerDraft.name.setOverrideError("Name is not unique.");
      } else {
        containerDraft.name.clearOverrideError();
      }
    },
    simulateServesTraffic(containerName: string, value: boolean) {
      let shouldWarn = false;
      // Enabling serves traffic for a container
      if (value) {
        for (let container of self.containersOtherThanByName(containerName)) {
          if (container.servesTraffic) {
            shouldWarn = true;
          }
        }
      }

      return shouldWarn;
    },
    setServesTraffic(containerName: string, value: boolean) {
      let shouldWarn = false;
      const containerToSetPort = self.containerByName(containerName);
      // Enabling serves traffic for a container
      if (value) {
        for (let container of self.containersOtherThanByName(containerName)) {
          if (container.servesTraffic) {
            shouldWarn = true;
          }
          container.setServesTraffic(false);
        }
        if (containerToSetPort) {
          containerToSetPort.setServesTraffic(true);
        } else {
          self.newContainer.setServesTraffic(true);
        }
        return;
      }

      // Disabling serves traffic for a container
      for (let container of self.containersOtherThanByName(containerName)) {
        container.resetServesTraffic();
      }
      if (containerToSetPort) {
        containerToSetPort.setServesTraffic(false);
      } else {
        self.newContainer.setServesTraffic(false);
      }
      return shouldWarn;
    },
    setUseGPU(containerName: string, value: boolean) {
      const containerToSetGPU = self.containerByName(containerName);
      // Enabling gpu for a container
      if (value) {
        for (let container of self.containersOtherThanByName(containerName)) {
          container.setUseGPU(false);
        }
        if (containerToSetGPU) {
          containerToSetGPU.setUseGPU(true);
        } else {
          self.newContainer.setUseGPU(true);
        }
        return;
      }

      // Disabling serves traffic for a container
      for (let container of self.containersOtherThanByName(containerName)) {
        container.resetUseGPU();
      }
      if (containerToSetGPU) {
        containerToSetGPU.setUseGPU(false);
      } else {
        self.newContainer.setUseGPU(false);
      }
    },
    setGPUNone(containerName: string) {
      const containerToSetGPU = self.containerByName(containerName);
      // Disabling serves traffic for a container
      for (let container of self.containersOtherThanByName(containerName)) {
        container.resetUseGPU();
      }
      if (containerToSetGPU) {
        containerToSetGPU.setUseGPU(false);
      } else {
        self.newContainer.setUseGPU(false);
      }
    },
    setGPUNvidia(containerName: string) {
      const containerToSetGPU = self.containerByName(containerName);
      // Enabling gpu for a container
      for (let container of self.containersOtherThanByName(containerName)) {
        container.setUseGPU(false);
      }
      if (containerToSetGPU) {
        containerToSetGPU.setUseGPU(true);
      } else {
        self.newContainer.setUseGPU(true);
      }
    },
    addLocalOptionsItem() {
      self.localOptions.push(
        getNewLocalOptionsItem(
          self.type.value,
          self._hasDefaultOptions || self.overrideDefaultOptions ? self.defaultOptions.asObject : undefined,
        ),
      );
      self.setMetricOptions();
    },
    confirm() {
      self._name = self.name.value;
      self.name.confirm();
      self._description = self.description.value;
      self.description.confirm();
      self._type = self.type.value;
      self.type.confirm();
      if (!self.description.value) {
        self.description.setInitialValue(self._name);
      }
      self._capacityAI = self.capacityAI;
      self._identityLink = self.identityLink;
      self.defaultOptions.confirm();
      for (let index = 0; index < self.localOptions.length; index++) {
        self.localOptions[index].confirm();
      }
      if (!self._hasDefaultOptions && self.overrideDefaultOptions) {
        self._hasDefaultOptions = true;
        self.overrideDefaultOptions = false;
      }
      self.container1.confirm();
      self.container2?.confirm();
      self.container3?.confirm();
      self.container4?.confirm();
      self.container5?.confirm();
      self.container6?.confirm();
      self.container7?.confirm();
      self.container8?.confirm();
      self.defaultOptionsConfirm();
      self.localOptionsConfirm();
      self.rolloutOptions.confirm();
      self.securityOptions.confirm();
      self.firewall.confirm();
      self.loadBalancer.confirm();
      self.job.confirm();
      self._supportDynamicTags = self.supportDynamicTags;
    },
  }))
  .actions((self) => ({
    addContainer() {
      if (!self.container2) {
        self.container2 = WorkloadDraftContainerModel.create();
        self.container2.manualAfterAttach();
        return 2;
      }
      if (!self.container3) {
        self.container3 = WorkloadDraftContainerModel.create();
        self.container3.manualAfterAttach();
        return 3;
      }
      if (!self.container4) {
        self.container4 = WorkloadDraftContainerModel.create();
        self.container4.manualAfterAttach();
        return 4;
      }
      if (!self.container5) {
        self.container5 = WorkloadDraftContainerModel.create();
        self.container5.manualAfterAttach();
        return 5;
      }
      if (!self.container6) {
        self.container6 = WorkloadDraftContainerModel.create();
        self.container6.manualAfterAttach();
        return 6;
      }
      if (!self.container7) {
        self.container7 = WorkloadDraftContainerModel.create();
        self.container7.manualAfterAttach();
        return 7;
      }
      if (!self.container8) {
        self.container8 = WorkloadDraftContainerModel.create();
        self.container8.manualAfterAttach();
        return 8;
      }
      return 8;
    },
    removeContainer() {
      if (self.container8) {
        mobxDestroy(self.container8);
        return 7;
      }
      if (self.container7) {
        mobxDestroy(self.container7);
        return 6;
      }
      if (self.container6) {
        mobxDestroy(self.container6);
        return 5;
      }
      if (self.container5) {
        mobxDestroy(self.container5);
        return 4;
      }
      if (self.container4) {
        mobxDestroy(self.container4);
        return 3;
      }
      if (self.container3) {
        mobxDestroy(self.container3);
        return 2;
      }
      if (self.container2) {
        mobxDestroy(self.container2);
        return 1;
      }
      return 1;
    },
  }))
  .views((self) => ({
    get containerWithGPU() {
      return self.allContainers.find((c) => c.useGPU)?.name.value || "";
    },
    get isLocalOptionsValid() {
      let res = true;
      for (let localOptionsItem of self.localOptions) {
        if (!localOptionsItem.isValid) res = false;
      }
      return res;
    },
    get isGeneralDirty() {
      let res = false;
      if (self.name.isDirty) res = true;
      if (self.description.isDirty) res = true;
      if (self.type.isDirty) res = true;
      if (self.capacityAI !== self._capacityAI) res = true;
      if (self.supportDynamicTags !== self._supportDynamicTags) res = true;
      return res;
    },
    get isGeneralValid() {
      let res = true;
      if (!self.name.isValid) res = false;
      return res;
    },
    get isDefaultOptionsDirty() {
      let res = false;
      if (self.capacityAI !== self._capacityAI) res = true;
      if (self.defaultOptions.isDirty) res = true;
      return res;
    },
    get isAnyOptionsDirty() {
      let res = false;
      if (self.overrideDefaultOptions) res = true;
      if (self.capacityAI !== self._capacityAI) res = true;
      if (self.defaultOptions.isDirty) res = true;
      for (let localOptionsItem of self.localOptions) {
        if (localOptionsItem.isDirty) res = true;
      }
      if (self.deletedLocalOption) res = true;
      return res;
    },
  }))
  .views((self) => ({
    get isServerlessServesTraffic() {
      if (!self.isServerless) {
        return true;
      }
      let servesTraffic = false;
      if (self.container1.servesTraffic) {
        servesTraffic = true;
      }
      if (self.container2 && self.container2.servesTraffic) {
        servesTraffic = true;
      }
      if (self.container3 && self.container3.servesTraffic) {
        servesTraffic = true;
      }
      if (self.container4 && self.container4.servesTraffic) {
        servesTraffic = true;
      }
      if (self.container5 && self.container5.servesTraffic) {
        servesTraffic = true;
      }
      if (self.container6 && self.container6.servesTraffic) {
        servesTraffic = true;
      }
      if (self.container7 && self.container7.servesTraffic) {
        servesTraffic = true;
      }
      if (self.container8 && self.container8.servesTraffic) {
        servesTraffic = true;
      }
      if (self.newContainer.isDirty && self.newContainer.servesTraffic) {
        servesTraffic = true;
      }
      return servesTraffic;
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.isGeneralDirty) res = true;
      if (self._identityLink !== self.identityLink) res = true;
      if (self.container1.isDirty) res = true;
      if (self.container2?.isDirty) res = true;
      if (self.container3?.isDirty) res = true;
      if (self.container4?.isDirty) res = true;
      if (self.container5?.isDirty) res = true;
      if (self.container6?.isDirty) res = true;
      if (self.container7?.isDirty) res = true;
      if (self.container8?.isDirty) res = true;
      if (self.isDefaultOptionsDirty) res = true;
      if (self.overrideDefaultOptions) res = true;
      if (self.isStateful || self.isStandard) {
        if (self.rolloutOptions.isDirty) res = true;
      }
      if (self.securityOptions.isDirty) res = true;
      if (self.isCron) {
        if (self.job.isDirty) res = true;
      }
      if (self.firewall.isDirty) res = true;
      if (self.loadBalancer.isDirty) res = true;
      if (self.tags.isDirty) res = true;
      return res;
    },
    get isDirtyReason() {
      let reason = "";
      if (self.isGeneralDirty) reason = "general";
      if (self._identityLink !== self.identityLink) reason = "identity link";
      if (self.container1.isDirty) reason = "c1 - " + self.container1?.isDirtyReason;
      if (self.container2?.isDirty) reason = "c2 - " + self.container2?.isDirtyReason;
      if (self.container3?.isDirty) reason = "c3 - " + self.container3?.isDirtyReason;
      if (self.container4?.isDirty) reason = "c4 - " + self.container4?.isDirtyReason;
      if (self.container5?.isDirty) reason = "c5 - " + self.container5?.isDirtyReason;
      if (self.container6?.isDirty) reason = "c6 - " + self.container6?.isDirtyReason;
      if (self.container7?.isDirty) reason = "c7 - " + self.container7?.isDirtyReason;
      if (self.container8?.isDirty) reason = "c8 - " + self.container8?.isDirtyReason;
      if (self.isDefaultOptionsDirty) reason = "defaultOptions";
      if (self.isStateful || self.isStandard) {
        if (self.rolloutOptions.isDirty) reason = self.rolloutOptions.isDirtyReason;
      }
      if (self.overrideDefaultOptions) reason = "override: default options";
      if (self.securityOptions.isDirty) reason = self.securityOptions.isDirtyReason;
      if (self.isCron) {
        if (self.job.isDirty) reason = self.job.isDirtyReason;
      }
      if (self.firewall.isDirty) reason = self.firewall.isDirtyReason;
      if (self.loadBalancer.isDirty) reason = "load balancer";
      if (self.tags.isDirty) reason = "tags";
      return reason;
    },
    get isValid() {
      let res = true;
      if (!self.isGeneralValid) res = false;
      if (!self.container1.isValid) res = false;
      if (self.container2 && !self.container2?.isValid) res = false;
      if (self.container3 && !self.container3?.isValid) res = false;
      if (self.container4 && !self.container4?.isValid) res = false;
      if (self.container5 && !self.container5?.isValid) res = false;
      if (self.container6 && !self.container6?.isValid) res = false;
      if (self.container7 && !self.container7?.isValid) res = false;
      if (self.container8 && !self.container8?.isValid) res = false;
      if (self.newContainer.isDirty && !self.newContainer.isValid) res = false;
      if (!self.isServerlessServesTraffic) res = false;
      if (!self.firewall.isValid) res = false;
      if (!self.loadBalancer.isValid) res = false;
      if (!self.defaultOptions.isValid) res = false;
      if (!self.isLocalOptionsValid) res = false;
      if (self.isStateful || self.isStandard) {
        if (!self.rolloutOptions.isValid) res = false;
      }
      if (!self.securityOptions.isValid) res = false;
      if (self.isCron) {
        if (!self.job.isValid) res = false;
      }
      if (!self.tags.isValid) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (!self.isGeneralValid) reason = "general";
      if (!self.container1.isValid) reason = "c1 " + self.container1.invalidReason;
      if (self.container2 && !self.container2?.isValid) reason = "c1 " + self.container1.invalidReason;
      if (self.container3 && !self.container3?.isValid) reason = "c2 " + self.container2?.invalidReason;
      if (self.container4 && !self.container4?.isValid) reason = "c3 " + self.container3?.invalidReason;
      if (self.container5 && !self.container5?.isValid) reason = "c4 " + self.container4?.invalidReason;
      if (self.container6 && !self.container6?.isValid) reason = "c5 " + self.container5?.invalidReason;
      if (self.container7 && !self.container7?.isValid) reason = "c6 " + self.container6?.invalidReason;
      if (self.container8 && !self.container8?.isValid) reason = "c7 " + self.container7?.invalidReason;
      if (self.newContainer.isDirty && !self.newContainer.isValid) reason = "new container";
      if (!self.isServerlessServesTraffic) reason = "serverless doesnt serve traffic";
      if (!self.firewall.isValid) reason = "firewall";
      if (!self.loadBalancer.isValid) reason = "load balancer";
      if (!self.defaultOptions.isValid) reason = "default";
      if (!self.isLocalOptionsValid) reason = "local options";
      if (self.isStateful || self.isStandard) {
        if (!self.rolloutOptions.isValid) reason = "rollout";
      }
      if (!self.securityOptions.isValid) reason = "security";
      if (self.isCron) {
        if (!self.job.isValid) reason = "job";
      }
      if (!self.tags.isValid) reason = "tags";
      return reason;
    },
    get hasContainerWithNameDirty() {
      let res = false;
      if (self.container1.name.isDirty) {
        res = true;
      }
      if (self.container2?.name.isDirty) {
        res = true;
      }
      if (self.container3?.name.isDirty) {
        res = true;
      }
      if (self.container4?.name.isDirty) {
        res = true;
      }
      if (self.container5?.name.isDirty) {
        res = true;
      }
      if (self.container6?.name.isDirty) {
        res = true;
      }
      if (self.container7?.name.isDirty) {
        res = true;
      }
      if (self.container8?.name.isDirty) {
        res = true;
      }
      return res;
    },
    next(pathname: string, enableAuthFilter: boolean, isAuthValid: boolean) {
      const next = {
        isActive: false,
        label: "Next",
        url: "",
      };
      if (!pathname) return next;
      if (pathname.includes("-create/-general")) {
        next.isActive = self.isGeneralValid;
        const containerName = self.container1.name.value || "Container 1";
        next.label = `Next (${containerName})`;
        next.url = `-containers/1`;
      } else if (pathname.includes("-containers/1")) {
        next.isActive = self.container1.isValid;
        if (self.container2) {
          const containerName = self.container2.name.value || "Container 2";
          next.label = `Next (${containerName})`;
          next.url = `-containers/2`;
        } else {
          next.label = "Next (Identity)";
          next.url = `-identity`;
        }
      } else if (pathname.includes("-containers/2")) {
        next.isActive = self.container2?.isValid || false;
        if (self.container3) {
          const containerName = self.container3.name.value || "Container 3";
          next.label = `Next (${containerName})`;
          next.url = `-containers/3`;
        } else {
          next.label = "Next (Identity)";
          next.url = `-identity`;
        }
      } else if (pathname.includes("-containers/3")) {
        next.isActive = self.container3?.isValid || false;
        if (self.container4) {
          const containerName = self.container4.name.value || "Container 4";
          next.label = `Next (${containerName})`;
          next.url = `-containers/4`;
        } else {
          next.label = "Next (Identity)";
          next.url = `-identity`;
        }
      } else if (pathname.includes("-containers/4")) {
        next.isActive = self.container4?.isValid || false;
        if (self.container5) {
          const containerName = self.container5.name.value || "Container 5";
          next.label = `Next (${containerName})`;
          next.url = `-containers/5`;
        } else {
          next.label = "Next (Identity)";
          next.url = `-identity`;
        }
      } else if (pathname.includes("-containers/5")) {
        next.isActive = self.container5?.isValid || false;
        if (self.container6) {
          const containerName = self.container6.name.value || "Container 6";
          next.label = `Next (${containerName})`;
          next.url = `-containers/6`;
        } else {
          next.label = "Next (Identity)";
          next.url = `-identity`;
        }
      } else if (pathname.includes("-containers/6")) {
        next.isActive = self.container6?.isValid || false;
        if (self.container7) {
          const containerName = self.container7.name.value || "Container 7";
          next.label = `Next (${containerName})`;
          next.url = `-containers/7`;
        } else {
          next.label = "Next (Identity)";
          next.url = `-identity`;
        }
      } else if (pathname.includes("-containers/7")) {
        next.isActive = self.container7?.isValid || false;
        if (self.container8) {
          const containerName = self.container8.name.value || "Container 8";
          next.label = `Next (${containerName})`;
          next.url = `-containers/8`;
        } else {
          next.label = "Next (Identity)";
          next.url = `-identity`;
        }
      } else if (pathname.includes("-containers/8")) {
        next.isActive = self.container8?.isValid || false;
        next.label = "Next (Identity)";
        next.url = `-identity`;
      } else if (pathname.includes("-identity")) {
        next.isActive = true;
        next.label = "Next (Options)";
        next.url = `-options`;
      } else if (pathname.includes("-options")) {
        if (self.isStateful || self.isStandard) {
          next.isActive = self.defaultOptions.isValid && self.isLocalOptionsValid;
          next.label = "Next (Rollout Options)";
          next.url = `-rollout`;
        } else {
          next.isActive = self.defaultOptions.isValid && self.isLocalOptionsValid;
          next.label = "Next (Security)";
          next.url = `-security`;
        }
      } else if (pathname.includes("-rollout")) {
        next.isActive = self.defaultOptions.isValid && self.isLocalOptionsValid && self.rolloutOptions.isValid;
        next.label = "Next (Security)";
        next.url = `-security`;
      } else if (pathname.includes("-security")) {
        next.isActive = self.defaultOptions.isValid && self.isLocalOptionsValid && self.securityOptions.isValid;
        next.label = "Next (Firewall)";
        next.url = `-firewall`;
      } else if (pathname.includes("-firewall")) {
        next.isActive = true;
        next.label = "Next (Load balancer)";
        next.url = `-loadbalancer`;
      } else if (pathname.includes("-loadbalancer")) {
        next.isActive = self.loadBalancer.isValid;
        next.label = "Next (Auth)";
        next.url = `-auth`;
      } else if (pathname.includes("-auth")) {
        if (self.isCron) {
          next.isActive =
            self.loadBalancer.isValid &&
            self.defaultOptions.isValid &&
            self.isLocalOptionsValid &&
            self.firewall.isValid &&
            (!enableAuthFilter || isAuthValid);
          next.label = "Next (Job)";
          next.url = `-job`;
        } else {
          next.isActive = (self.loadBalancer.isValid && !enableAuthFilter) || isAuthValid;
          next.label = "Next (Tags)";
          next.url = `-tags`;
        }
      } else if (pathname.includes("-job")) {
        next.isActive =
          !self.isCron ||
          (self.loadBalancer.isValid &&
            self.defaultOptions.isValid &&
            self.isLocalOptionsValid &&
            self.firewall.isValid &&
            self.job.isValid);
        next.label = "Next (Tags)";
        next.url = `-tags`;
      }
      return next;
    },
  }))
  .views((self) => ({
    get asPatch() {
      const spec: any = {
        type: self.type.value,
        containers: [
          self.container1.asObject,
          self.container2?.asObject,
          self.container3?.asObject,
          self.container4?.asObject,
          self.container5?.asObject,
          self.container6?.asObject,
          self.container7?.asObject,
          self.container8?.asObject,
          self.newContainer.isDirty ? self.newContainer.asObject : undefined,
        ].filter(Boolean),
        firewallConfig: self.firewall.asObject,
      };
      if (Object.keys(self.securityOptions.asObject).length > 0) {
        spec.securityOptions = self.securityOptions.asObject;
      }
      if (DIRECT_LB_ENABLED) {
        spec.loadBalancer = self.loadBalancer.asObject;
      }
      if (self.sidecar) {
        spec.sidecar = self.sidecar;
      }
      spec.supportDynamicTags = self.supportDynamicTags;
      if (self.localOptions.length > 0) {
        spec.localOptions = self.localOptions.map((l) => l.asObject);
      }
      if (!!self.identityLink) {
        spec.identityLink = self.identityLink;
      }
      if ((self.isStateful || self.isStandard) && self.rolloutOptions.configure) {
        spec.rolloutOptions = self.rolloutOptions.asObject;
      }
      if (self._hasDefaultOptions || (!self._hasDefaultOptions && self.overrideDefaultOptions)) {
        spec.defaultOptions = self.defaultOptions.asObject;
      }
      if (self.isCron) {
        spec.job = self.job.asObject;
      }
      if (self.isCron) {
        if (spec.defaultOptions) {
          delete spec.defaultOptions.autoscaling;
        }
        for (let opt of spec.localOptions || []) {
          delete opt.autoscaling;
        }
      }
      const body: any = {
        name: self.name.value,
        description: self.description.value || self.name.value,
        "$replace/spec": spec,
      };
      return body;
    },
  }))
  .views((self) => ({
    get asObject() {
      let res = JSON.parse(JSON.stringify(self.asPatch));

      res.tags = self.tags.asObject;

      const _spec = res["$replace/spec"];
      res.spec = _spec;
      delete res["$replace/spec"];

      return res;
    },
  }))
  .views((self) => ({
    get secretLinks() {
      const secretPrefix = "cpln://secret/";

      const secretNames: string[] = [];

      let inheritsGVCVars = false;
      for (let c of self.allContainers) {
        if (c.inheritEnv) {
          inheritsGVCVars = true;
          break;
        }
      }

      if (inheritsGVCVars) {
        for (let env of self._gvcEnv) {
          if (env.value.startsWith(secretPrefix)) {
            const secretName = env.value.split(secretPrefix)[1].split(".")[0];
            if (!secretNames.includes(secretName)) {
              secretNames.push(secretName);
            }
          }
        }
      }

      for (const c of self.allContainers) {
        for (let env of c.env.asArray) {
          if (env.value.startsWith(secretPrefix)) {
            const secretName = env.value.split(secretPrefix)[1].split(".")[0];
            if (!secretNames.includes(secretName)) {
              secretNames.push(secretName);
            }
          }
        }
        for (let volume of c.volumes.asArray) {
          if (volume.uri.startsWith(secretPrefix)) {
            const secretName = volume.uri.split(secretPrefix)[1].split(".")[0];
            if (!secretNames.includes(secretName)) {
              secretNames.push(secretName);
            }
          }
        }
      }

      return secretNames.map((name) => `/org/${ConsoleContext.org}/secret/${name}`);
    },
  }))
  .views((self) => ({
    get hasSecretRef() {
      return self.secretLinks.length > 0;
    },
  }))
  .views((self) => ({
    get identityValid(): "unneeded" | "required" | "unknown" | boolean {
      if (!self.hasSecretRef) {
        return "unneeded";
      }
      if (!self.identityLink) {
        return "required";
      }
      if (self.validIdentityLinks.includes(self.identityLink)) {
        return true;
      }
      if (self.invalidIdentityLinks.includes(self.identityLink)) {
        return false;
      }
      return "unknown";
    },
  }))
  .actions((self) => ({
    removeValidIdentityLink(link: string) {
      const _links: string[] = JSON.parse(JSON.stringify(self.validIdentityLinks));
      self.validIdentityLinks.clear();
      for (let _link of _links.filter((l) => l !== link)) {
        self.validIdentityLinks.push(_link);
      }
    },
    removeInvalidIdentityLink(link: string) {
      const _links: string[] = JSON.parse(JSON.stringify(self.invalidIdentityLinks));
      self.invalidIdentityLinks.clear();
      for (let _link of _links.filter((l) => l !== link)) {
        self.invalidIdentityLinks.push(_link);
      }
    },
  }))
  .actions((self) => ({
    addValidIdentityLink(link: string) {
      self.removeInvalidIdentityLink(link);
      if (!self.validIdentityLinks.includes(link)) {
        self.validIdentityLinks.push(link);
      }
    },
    addInvalidIdentityLink(link: string) {
      self.removeValidIdentityLink(link);
      if (!self.invalidIdentityLinks.includes(link)) {
        self.invalidIdentityLinks.push(link);
      }
    },
  }))
  .actions((self) => {
    const processIdentity: (identityLink: string) => Promise<void> = flow(function* (identityLink: string) {
      if (!self.hasSecretRef) {
        return;
      }
      self.processingIdentity = true;
      // fetch all policies
      let queryLink = parentLink("policy") + "/-query";
      let policies: Policy[] = [];
      const queryBody = {
        kind: "policy",
        spec: {
          match: "all",
          terms: [
            {
              op: "=",
              property: "targetKind",
              value: "secret",
            },
          ],
        },
      };
      let policiesRes;
      const { data }: any = yield request({ method: "post", url: queryLink, body: queryBody });
      policiesRes = data;
      for (let item of policiesRes.items) {
        policies.push(item);
      }
      let nextLink = policiesRes.links.find((link: any) => link.rel === "next")?.href;
      while (nextLink) {
        const { data: nextData } = yield request({ url: nextLink });
        policiesRes = nextData;
        for (let item of policiesRes.items) {
          policies.push(item);
        }
        nextLink = policiesRes.links.find((link: any) => link.rel === "next")?.href;
      }

      // filter reveal, edit, manage permissions for this identity
      policies = policies.filter(
        (p) =>
          p.bindings &&
          Array.isArray(p.bindings) &&
          p.bindings.some(
            (b) =>
              b.permissions &&
              Array.isArray(b.permissions) &&
              (b.permissions.includes("reveal") ||
                b.permissions.includes("edit") ||
                b.permissions.includes("manage")) &&
              b.principalLinks &&
              Array.isArray(b.principalLinks) &&
              b.principalLinks.includes(identityLink),
          ),
      );

      // checks if target item is all for any of the policies, then we have validated the requirements
      if (policies.some((p) => p.target === "all")) {
        self.addValidIdentityLink(identityLink);
        self.processingIdentity = false;
        return;
      }

      // TODO if policy has a query, execute the query and append to policy object
      queryLink = parentLink("secret") + "/-query";
      for (let p of policies) {
        if (!p.targetQuery) {
          continue;
        }

        const { data: queryResult } = yield request({ method: "post", url: queryLink, body: p.targetQuery });
        // @ts-ignore
        p.queryResult = queryResult;
      }

      const coveredSecretLinks: string[] = [];

      for (let p of policies) {
        for (let secretLink of self.secretLinks) {
          if (coveredSecretLinks.includes(secretLink)) {
            continue;
          }
          if (
            p.targetLinks &&
            Array.isArray(p.targetLinks) &&
            p.targetLinks.includes(secretLink) &&
            !coveredSecretLinks.includes(secretLink)
          ) {
            coveredSecretLinks.push(secretLink);
          }

          if (p.targetQuery) {
            const queryResult: any = (p as any).queryResult;
            if (
              queryResult.items &&
              Array.isArray(queryResult.items) &&
              queryResult.items.some((s: Secret) => linksOf(s).self === secretLink) &&
              !coveredSecretLinks.includes(secretLink)
            ) {
              coveredSecretLinks.push(secretLink);
            }
          }
        }
      }

      if (self.secretLinks.length === coveredSecretLinks.length) {
        self.addValidIdentityLink(identityLink);
      } else {
        self.addInvalidIdentityLink(identityLink);
      }
      self.processingIdentity = false;
    });
    return { processIdentity };
  });

export interface WorkloadDraftMobx extends Instance<typeof WorkloadDraftModel> {}

/*
org link
gvc link
gvc domain
gvc alias
workload link from its edited/current name
*/
export const WorkloadDraftStoreModel = types
  .model({
    gvcEnv: types.frozen(),
    gvcName: types.string,
    gvcLink: types.string,
    gvcDomain: types.string,
    gvcAlias: types.string,
    orgName: types.string,
    orgLink: types.string,
    edit: types.maybe(WorkloadDraftModel),
  })
  .actions((self) => ({
    setInfo(gvcDomain: string, gvcAlias: string, gvcEnv: any) {
      const { org, gvc } = ConsoleContext;
      self.orgName = org!;
      self.orgLink = resourceLink("org", org!);
      self.gvcName = gvc!;
      self.gvcLink = resourceLink("gvc", gvc!);
      self.gvcDomain = gvcDomain;
      self.gvcAlias = gvcAlias;
      self.gvcEnv = gvcEnv;
    },
    new() {
      self.edit = WorkloadDraftModel.create({ _new: true, _gvcEnv: self.gvcEnv });
      self.edit.firewall.setInfo(self.orgName, self.gvcName);
    },
    start(workload: WorkloadMobx) {
      self.edit = WorkloadDraftModel.create({
        _gvcEnv: self.gvcEnv,
        _version: workload.version,
        _hasDefaultOptions: !!workload.spec.defaultOptions,
        _type: workload.spec.type,
        _name: workload.name,
        _description: workload.description,
        _capacityAI: workload.spec.defaultOptions?.capacityAI,
        _supportDynamicTags: workload.spec.supportDynamicTags,
        defaultOptions: getDraftDefaultOptions(workload.spec.type, workload.spec.defaultOptions),
        _localOptions: getDraftLocalOptions(workload.spec.type, workload.spec.localOptions),
        localOptions: getDraftLocalOptions(workload.spec.type, workload.spec.localOptions),
        sidecar: workload.spec.sidecar ? JSON.parse(JSON.stringify(workload.spec.sidecar)) : undefined,
        firewall: WorkloadDraftFirewallModel.create({
          _gvcName: self.gvcName,
          _orgName: self.orgName,
          _workloadName: workload.name,
          _internal_inboundAllowType:
            workload.spec.firewallConfig.internal.inboundAllowType === "same-gvc" &&
            workload.spec.firewallConfig.internal.inboundAllowWorkload.length > 0
              ? "same-gvc-and-workload-list"
              : workload.spec.firewallConfig.internal.inboundAllowType,
          _internal_inboundAllowWorkload: [
            ...workload.spec.firewallConfig.internal.inboundAllowWorkload.filter((link) => link !== workload.selfLink),
          ],
          _internal_inboundAllowItself: workload.spec.firewallConfig.internal.inboundAllowWorkload.includes(
            workload.selfLink,
          ),
          _external_inboundAllowCIDR: [...workload.spec.firewallConfig.external.inboundAllowCIDR],
          _external_outboundAllowCIDR: [...workload.spec.firewallConfig.external.outboundAllowCIDR],
          _external_outboundAllowHostname: [...workload.spec.firewallConfig.external.outboundAllowHostname],
          _external_outboundAllowPort: workload.spec.firewallConfig.external.outboundAllowPort.map((p) => ({
            number: p.number,
            protocol: p.protocol as any,
          })),
        }),
        loadBalancer: WorkloadDraftLoadBalancerModel.create({
          direct: WorkloadDraftLoadBalancerDirectModel.create({
            _enabled: workload.spec.loadBalancer?.direct?.enabled,
            _ipSet: workload.spec.loadBalancer?.direct?.ipSet,
            _ports: (workload.spec.loadBalancer?.direct?.ports || []).map((i) => ({
              externalPort: i.externalPort,
              containerPort: i.containerPort,
              protocol: i.protocol,
              scheme: i.scheme,
            })),
          }),
          _enabledGeoHeaders: workload.spec.loadBalancer?.geoLocation?.enabled,
          _geoHeaderAsn: workload.spec.loadBalancer?.geoLocation?.headers?.asn,
          _geoHeaderCity: workload.spec.loadBalancer?.geoLocation?.headers?.city,
          _geoHeaderCountry: workload.spec.loadBalancer?.geoLocation?.headers?.country,
          _geoHeaderRegion: workload.spec.loadBalancer?.geoLocation?.headers?.region,
        }),
        rolloutOptions: workload.spec.rolloutOptions
          ? WorkloadDraftRolloutOptionsModel.create({
              _configure: true,
              _maxSurgeReplicas: workload.spec.rolloutOptions.maxSurgeReplicas,
              _minReadySeconds: workload.spec.rolloutOptions.minReadySeconds,
              _scalingPolicy: workload.spec.rolloutOptions.scalingPolicy,
            })
          : WorkloadDraftRolloutOptionsModel.create({
              _configure: false,
            }),
        securityOptions: workload.spec.securityOptions
          ? WorkloadDraftSecurityOptionsModel.create({
              _filesystemGroupId: workload.spec.securityOptions.filesystemGroupId,
            })
          : WorkloadDraftSecurityOptionsModel.create(),
        job: getJobSpec(workload.spec.job),
        _identityLink: workload.spec.identityLink || "",
        tags: TagsNewModel.create({
          tags: kindMobxToTagsModel(workload),
        }),
        container1: getDraftContainer(workload.spec.containers[0], workload),
        container2:
          workload.spec.containers.length > 1 ? getDraftContainer(workload.spec.containers[1]!, workload) : undefined,
        container3:
          workload.spec.containers.length > 2 ? getDraftContainer(workload.spec.containers[2]!, workload) : undefined,
        container4:
          workload.spec.containers.length > 3 ? getDraftContainer(workload.spec.containers[3]!, workload) : undefined,
        container5:
          workload.spec.containers.length > 4 ? getDraftContainer(workload.spec.containers[4]!, workload) : undefined,
        container6:
          workload.spec.containers.length > 5 ? getDraftContainer(workload.spec.containers[5]!, workload) : undefined,
        container7:
          workload.spec.containers.length > 6 ? getDraftContainer(workload.spec.containers[6]!, workload) : undefined,
        container8:
          workload.spec.containers.length > 7 ? getDraftContainer(workload.spec.containers[7]!, workload) : undefined,
      });
    },
    reset() {
      self.edit?.reset();
    },
  }));
export interface WorkloadDraftStoreMobx extends Instance<typeof WorkloadDraftStoreModel> {}

function getDrafProbe(probe: HealthCheckSpecMobx | undefined) {
  if (!probe) return;
  return WorkloadDraftContainerProbeModel.create({
    _method: probe.method,
    _exec_command: probe.exec && probe.exec.command && probe.exec.command.length > 0 ? probe.exec.command[0] : "",
    exec_args: ListOfItemsModel.create({
      _items:
        probe.exec && probe.exec.command && probe.exec.command.length > 0
          ? probe.exec.command.slice(1).map((c) => ({ firstValue: c }))
          : [],
    }),
    _tcp_port: probe.tcpSocket?.port,
    _grpc_port: probe.grpc?.port,
    _http_path: probe.httpGet?.path,
    _http_port: probe.httpGet?.port,
    _http_scheme: probe.httpGet?.scheme,
    http_headers: ListOfItemsModel.create({
      _items:
        probe.httpGet && probe.httpGet.httpHeaders && probe.httpGet.httpHeaders.length > 0
          ? probe.httpGet.httpHeaders.map((h) => ({ firstValue: h.name, secondValue: h.value }))
          : [],
    }),
    _initialDelaySeconds: probe.initialDelaySeconds,
    _periodSeconds: probe.periodSeconds,
    _timeoutSeconds: probe.timeoutSeconds,
    _successThreshold: probe.successThreshold,
    _failureThreshold: probe.failureThreshold,
  });
}

function getDraftMetrics(metrics: ContainerMetricsMobx | undefined) {
  if (!metrics) return;
  return WorkloadDraftContainerMetricsModel.create({
    _useMetrics: true,
    _path: metrics.path,
    _port: metrics.port,
  });
}

function getDraftContainer(container: ContainerSpecMobx, workload: WorkloadMobx) {
  const capacityAI = workload.spec.defaultOptions?.capacityAI;
  const protocol =
    workload.isServerless && workload.tags["cpln/protocol"]
      ? workload.tags["cpln/protocol"]
      : workload.isStandard
      ? "http2"
      : defaultValues.workload.container.port.protocol;

  return WorkloadDraftContainerModel.create({
    _capacityAI: capacityAI,
    _ports: container.port
      ? [{ protocol, number: container.port }]
      : container.ports
      ? container.ports.map((p) => ({ number: p.number, protocol: p.protocol }))
      : [],
    _command: container.command,
    args: ListOfItemsModel.create({ _items: container.args.map((arg) => ({ firstValue: arg })) }),
    _postStartCommand: container.lifecycle?.postStart?.exec?.command
      ? container.lifecycle.postStart.exec.command[0]
      : "",
    postStartArgs: ListOfItemsModel.create({
      _items: container.lifecycle?.postStart?.exec?.command
        ? container.lifecycle?.postStart?.exec?.command.slice(1).map((arg) => ({ firstValue: arg }))
        : [],
    }),
    _preStopCommand: container.lifecycle?.preStop?.exec?.command ? container.lifecycle.preStop.exec.command[0] : "",
    preStopArgs: ListOfItemsModel.create({
      _items: container.lifecycle?.preStop?.exec?.command
        ? container.lifecycle?.preStop?.exec?.command.slice(1).map((arg) => ({ firstValue: arg }))
        : [],
    }),
    _image: container.image,
    _name: container.name,
    isNameManuallyChanged: true,

    _workingDir: container.workingDir,
    _inheritEnv: container.inheritEnv,
    cpu: CpuModel.create({
      _label: capacityAI ? "Max" : "Reserved",
      _defaultValue: container.cpu,
    }),
    memory: MemoryModel.create({
      _label: capacityAI ? "Max" : "Reserved",
      _defaultValue: container.memory,
    }),
    minCpu: CpuModel.create({
      _label: "Min",
      _defaultValue: container.minCpu,
    }),
    minMemory: MemoryModel.create({
      _label: "Min",
      _defaultValue: container.minMemory,
    }),
    env:
      container.env.length > 0
        ? EnvVarsModel.create({
            vars: container.env.map((env, idx) => ({ name: env.name, value: env.value })),
          })
        : undefined,
    volumes:
      container.volumes.length > 0
        ? VolumesModel.create({
            volumes: getSnapshot(container.volumes),
          })
        : undefined,
    livenessProbe: getDrafProbe(container.livenessProbe),
    readinessProbe: getDrafProbe(container.readinessProbe),
    _useGPU: !!container.gpu && !!container.gpu.nvidia,
    gpu:
      !!container.gpu && !!container.gpu.nvidia
        ? WorkloadDraftContainerGPUModel.create({
            _vendor: "nvidia",
            _nvidia_model: container.gpu.nvidia.model,
            _nvidia_quantity: container.gpu.nvidia.quantity,
          })
        : WorkloadDraftContainerGPUModel.create(),
    metrics: getDraftMetrics(container.metrics),
  });
}

function getDefaultMetricValueOfWorkloadType(type: string) {
  // ignored for cron
  let defaultMetricValue: "cpu" | "concurrency" = "concurrency";
  if (type === "standard" || type === "stateful") {
    defaultMetricValue = "cpu";
  }
  if (type === "serverless") {
    defaultMetricValue = "concurrency";
  }
  return defaultMetricValue;
}

function getDefaultCapacityAIOfMetric(metric: string) {
  return metric !== "cpu";
}

function getDraftDefaultOptions(type: string, defaultOptions?: DefaultOptionsMobx) {
  const defaultMetricValue = getDefaultMetricValueOfWorkloadType(type);
  const defaultCapacityAI = getDefaultCapacityAIOfMetric(defaultMetricValue);

  let metric: string = defaultOptions?.autoscaling?.metric || defaultMetricValue;
  let target: number | undefined = undefined;
  const multi = defaultOptions?.autoscaling?.multi;
  if (!!multi && multi.length > 0) {
    if (multi.length === 1) {
      metric = multi[0].metric;
      target = multi[0].target;
    } else if (multi.length === 2) {
      metric = "multi";
      target = defaultOptions?.autoscaling?.target;
    }
  }

  return WorkloadDraftOptionsModel.create({
    isLocal: false,
    isNew: false,
    _hasAutoscaling: !!defaultOptions?.autoscaling,
    _metric: metric,
    _scaleToZeroDelay: defaultOptions?.autoscaling?.scaleToZeroDelay,
    _maxConcurrency: defaultOptions?.autoscaling?.maxConcurrency,
    _minScale: defaultOptions?.autoscaling?.minScale,
    _maxScale: defaultOptions?.autoscaling?.maxScale,
    _target: target,
    _multiCpuTarget: defaultOptions?.autoscaling?.multi?.find((m) => m.metric === "cpu")?.target,
    _multiMemoryTarget: defaultOptions?.autoscaling?.multi?.find((m) => m.metric === "memory")?.target,
    _metricPercentile: defaultOptions?.autoscaling?.metricPercentile,
    _capacityAI: defaultOptions?.capacityAI || defaultCapacityAI,
    _debug: defaultOptions?.debug,
    _suspend: defaultOptions?.suspend,
    _timeoutSeconds: defaultOptions?.timeoutSeconds,
  });
}

function getNewLocalOptionsItem(type: string, defaultOptions: any | undefined) {
  const defaultMetricValue = getDefaultMetricValueOfWorkloadType(type);
  const defaultCapacityAI = getDefaultCapacityAIOfMetric(defaultMetricValue);

  if (!defaultOptions)
    return WorkloadDraftOptionsModel.create({
      isLocal: true,
      isNew: true,
      _hasAutoscaling: false,
      _metric: defaultMetricValue,
      _capacityAI: defaultCapacityAI,
    });

  let metric: string = defaultOptions?.autoscaling?.metric || defaultMetricValue;
  let target: number | undefined = undefined;
  const multi = defaultOptions?.autoscaling?.multi;
  if (!!multi && multi.length > 0) {
    if (multi.length === 1) {
      metric = multi[0].metric;
      target = multi[0].target;
    } else if (multi.length === 2) {
      metric = "multi";
      target = defaultOptions?.autoscaling?.target;
    }
  }

  return WorkloadDraftOptionsModel.create({
    isLocal: true,
    isNew: true,
    _hasAutoscaling: !!defaultOptions.autoscaling,
    _metric: metric,
    _scaleToZeroDelay: defaultOptions.autoscaling?.scaleToZeroDelay,
    _maxConcurrency: defaultOptions.autoscaling?.maxConcurrency,
    _minScale: defaultOptions.autoscaling?.minScale,
    _maxScale: defaultOptions.autoscaling?.maxScale,
    _target: target,
    _multiCpuTarget: defaultOptions.autoscaling?.multi?.find((m) => m.metric === "cpu")?.target,
    _multiMemoryTarget: defaultOptions.autoscaling?.multi?.find((m) => m.metric === "memory")?.target,
    _metricPercentile: defaultOptions.autoscaling?.metricPercentile,
    _capacityAI: defaultOptions.capacityAI || defaultCapacityAI,
    _debug: defaultOptions.debug,
    _suspend: defaultOptions.suspend,
    _timeoutSeconds: defaultOptions.timeoutSeconds,
  });
}

function getDraftLocalOptions(type: string, localOptions: LocalOptionsMobx[] = []) {
  const defaultMetricValue = getDefaultMetricValueOfWorkloadType(type);
  const defaultCapacityAI = getDefaultCapacityAIOfMetric(defaultMetricValue);

  const res: WorkloadDraftOptionsMobx[] = [];

  for (let localOptionsItem of localOptions) {
    let metric: string = localOptionsItem?.autoscaling?.metric || defaultMetricValue;
    let target: number | undefined = undefined;
    const multi = localOptionsItem?.autoscaling?.multi;
    if (!!multi && multi.length > 0) {
      if (multi.length === 1) {
        metric = multi[0].metric;
        target = multi[0].target;
      } else if (multi.length === 2) {
        metric = "multi";
        target = localOptionsItem?.autoscaling?.target;
      }
    }

    res.push(
      WorkloadDraftOptionsModel.create({
        isLocal: true,
        isNew: false,
        _hasAutoscaling: !!localOptionsItem.autoscaling,
        _location: localOptionsItem.location,
        _metric: metric,
        _scaleToZeroDelay: localOptionsItem.autoscaling?.scaleToZeroDelay,
        _maxConcurrency: localOptionsItem.autoscaling?.maxConcurrency,
        _minScale: localOptionsItem.autoscaling?.minScale,
        _maxScale: localOptionsItem.autoscaling?.maxScale,
        _target: target,
        _multiCpuTarget: localOptionsItem.autoscaling?.multi?.find((m) => m.metric === "cpu")?.target,
        _multiMemoryTarget: localOptionsItem.autoscaling?.multi?.find((m) => m.metric === "memory")?.target,
        _metricPercentile: localOptionsItem.autoscaling?.metricPercentile,
        _capacityAI: localOptionsItem.capacityAI || defaultCapacityAI,
        _debug: localOptionsItem.debug,
        _suspend: localOptionsItem.suspend,
        _timeoutSeconds: localOptionsItem.timeoutSeconds,
      }),
    );
  }
  return res;
}

function getJobSpec(jobSpec?: JobSpecMobx): WorkloadDraftJobMobx {
  if (!jobSpec) return WorkloadDraftJobModel.create();

  return WorkloadDraftJobModel.create({
    _concurrencyPolicy: jobSpec.concurrencyPolicy || "Forbid",
    _historyLimit: jobSpec.historyLimit || 5,
    _restartPolicy: jobSpec.restartPolicy || "Never",
    _schedule: jobSpec.schedule || "* * * * *",
    _activeDeadlineSeconds: jobSpec.activeDeadlineSeconds === undefined ? undefined : jobSpec.activeDeadlineSeconds,
  });
}
