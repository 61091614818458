import * as React from "react";
import { AutoScalingDoc } from "./autoScalingDoc";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { NGInputAdapter } from "../../newcomponents/input/inputAdapter";
import { NGLabel } from "../../newcomponents/text/label";
import { NGLabelText } from "../../newcomponents/text/labelText";
import { NGSelect } from "../../newcomponents/select/ngselect";
import { NGSwitch } from "../../newcomponents/switch";
import { observer } from "mobx-react-lite";
import { RevertButton } from "./create/localOptionsItem";
import { useLocation } from "react-router-dom";
import { WorkloadDraftMobx } from "../../mst/stores/workload.draft";
import clsx from "clsx";
import { NGFormLabel } from "../../newcomponents/text/formLabel";
import { NGInput } from "../../newcomponents/input/input";
import { NGError } from "../../newcomponents/text/error";
import { InfoTooltip } from "../../components/InfoTooltip";
import NGAlert from "../../newcomponents/alert";
import { Location } from "../../schema/types/location";

interface Props {
  draft: WorkloadDraftMobx;
  gvcLocations: string[];
}
const LocalOptionsItemRaw: React.FC<Props> = ({ draft, gvcLocations }) => {
  const { pathname } = useLocation();
  const index = pathname.split("/-options/")[1];
  const localOptions = draft.localOptions[Number(index)];

  function locationFilterFn(locationItem: Location) {
    const alreadySelectedLocations = draft.localOptions
      .filter((_, _index) => Number(index) !== _index)
      .map((locOpt) => locOpt.location.value);
    let showThisLocation = true;
    if (alreadySelectedLocations.includes(locationItem.name)) showThisLocation = false;
    if (!gvcLocations.includes(locationItem.name)) showThisLocation = false;
    return showThisLocation;
  }

  const revert = {
    capacityAI() {
      localOptions.setCapacityAI(draft.defaultOptions.capacityAI, false);
    },
    debug() {
      localOptions.setDebug(draft.defaultOptions.debug);
    },
    suspend() {
      localOptions.setSuspend(draft.defaultOptions.suspend);
    },
    timeoutSeconds() {
      localOptions.timeoutSeconds.setValue(draft.defaultOptions.timeoutSeconds.value);
    },
    metric() {
      draft.setMetric(draft.defaultOptions.metric.value as any, Number(index));
    },
    targetMetricPercentile() {
      localOptions.target.setValue(draft.defaultOptions.target.value);
      localOptions.metricPercentile.setValue(draft.defaultOptions.metricPercentile.value);
    },
    multiTargets() {
      localOptions.multiCpuTarget.setValue(draft.defaultOptions.multiCpuTarget.value);
      localOptions.multiMemoryTarget.setValue(draft.defaultOptions.multiMemoryTarget.value);
    },
    minScale() {
      localOptions.minScale.setValue(draft.defaultOptions.minScale.value);
    },
    maxScale() {
      localOptions.maxScale.setValue(draft.defaultOptions.maxScale.value);
    },
    scaleToZeroDelay() {
      localOptions.scaleToZeroDelay.setValue(draft.defaultOptions.scaleToZeroDelay.value);
    },
    maxConcurrency() {
      localOptions.maxConcurrency.setValue(draft.defaultOptions.maxConcurrency.value);
    },
  };

  if (!localOptions) {
    return <div style={{ height: 500 }} />;
  }

  // Checking this because a single button is used for two things
  let canRevertTargetMetricPercentile = false;
  if (draft.defaultOptions.target.value !== localOptions.target.value) {
    canRevertTargetMetricPercentile = true;
  }
  if (
    localOptions.metric.value === "latency" &&
    draft.defaultOptions.metricPercentile.value !== localOptions.metricPercentile.value
  ) {
    canRevertTargetMetricPercentile = true;
  }

  let canRevertMultiTargets = false;
  if (
    draft.defaultOptions.multiCpuTarget.value !== localOptions.multiCpuTarget.value ||
    draft.defaultOptions.multiMemoryTarget.value !== localOptions.multiMemoryTarget.value
  ) {
    canRevertMultiTargets = true;
  }

  const currentLocation = localOptions.location.value || "notset";

  return (
    <>
      {localOptions.isNew ? (
        <NGFormElement
          label={localOptions.location.label}
          name={`${index}location`}
          kind={"location"}
          required
          as={"kindselect"}
          value={localOptions.location.value}
          onChange={localOptions.location.setValue}
          kindSelectProps={{ filterOptions: locationFilterFn, fetchAll: true }}
        />
      ) : null}
      {draft.isStateful || draft.isCron ? null : (
        <>
          <div className={`mb-4 flex items-center`}>
            <NGSwitch
              value={localOptions.capacityAI}
              onChange={(value: boolean) => localOptions.setCapacityAI(value, false)}
              isDisabled={["cpu", "memory"].includes(localOptions.metric.value)}
            >
              <NGLabelText>Capacity AI</NGLabelText>
            </NGSwitch>
            <InfoTooltip
              title={[
                `Enables intelligent dynamic resource allocation of CPU and Memory for each container by setting resource reservations based on usage data realtime observation.`,
                `Capacity AI can significantly reduce cost. It is well suited for workloads with steady usage characteristics and less ideally suited for workloads with significant spikes in usage.`,
              ]}
            />
            {["cpu", "memory"].includes(localOptions.metric.value) ? null : draft.defaultOptions.capacityAI !==
              localOptions.capacityAI ? (
              <RevertButton onClick={revert.capacityAI} />
            ) : null}
          </div>
          {["cpu", "memory"].includes(localOptions.metric.value) ? (
            <NGAlert
              className="mb-4"
              message={`Capacity AI is not available when Scaling Strategy is set to ${
                localOptions.metric.value === "cpu" ? "CPU Utilization" : "Multi"
              } `}
            />
          ) : null}
        </>
      )}
      {draft.isCron ? null : (
        <div className="mb-4 flex items-center">
          <NGSwitch value={localOptions.debug} onChange={localOptions.setDebug}>
            <NGLabelText>Debug</NGLabelText>
          </NGSwitch>
          <InfoTooltip title={`Adds debug response headers when the headers "x-cpln-debug: true" is in the request.`} />
          {draft.defaultOptions.debug !== localOptions.debug ? <RevertButton onClick={revert.debug} /> : null}
        </div>
      )}
      <div className="mb-4 flex items-center">
        <NGSwitch value={localOptions.suspend} onChange={localOptions.setSuspend}>
          <NGLabelText>Suspend</NGLabelText>
        </NGSwitch>
        <InfoTooltip title="If checked, the workload will be in a suspended mode and not running." />
        {draft.defaultOptions.suspend !== localOptions.suspend ? <RevertButton onClick={revert.suspend} /> : null}
      </div>
      {draft.isCron ? null : (
        <div className={clsx("mb-4")}>
          <NGFormLabel
            name={`${currentLocation}timeoutSeconds`}
            required={localOptions.timeoutSeconds.isRequired}
            invalid={!localOptions.timeoutSeconds.isValid}
          >
            {localOptions.timeoutSeconds.label}
          </NGFormLabel>
          <div>Request to the workload will timeout when reaching this number of seconds.</div>
          <div className="flex items-center">
            <NGInput
              value={localOptions.timeoutSeconds.value}
              onChange={(e) => localOptions.timeoutSeconds.setValue(e.target.value)}
              name={`${currentLocation}timeoutSeconds`}
              required={localOptions.timeoutSeconds.isRequired}
              invalid={!localOptions.timeoutSeconds.isValid}
              style={{ width: 450 }}
            />
            <InfoTooltip
              title={[
                `The maximum request duration in seconds before the workload will timeout.`,
                `This timeout amount can be reached when waiting for the workload to respond or when waiting for a new workload to become available when using Autoscaling.`,
                `The minimum value is 1 second and the maximum value is 3600 seconds (1 hour).`,
              ]}
            />
            {draft.defaultOptions.timeoutSeconds.value !== localOptions.timeoutSeconds.value ? (
              <RevertButton onClick={revert.timeoutSeconds} shift />
            ) : null}
          </div>
          <div style={{ width: 450 }}>
            {localOptions.timeoutSeconds.error ? <NGError>{localOptions.timeoutSeconds.error}</NGError> : null}
          </div>
        </div>
      )}
      {draft.isStandard || draft.isServerless || draft.isStateful ? (
        <>
          {!localOptions._hasAutoscaling ? (
            <NGSwitch
              data-testid="switch-override-autoscaling"
              onChange={(value) => localOptions.setOverrideAutoscaling(value)}
              value={localOptions.overrideAutoscaling}
            >
              <NGLabelText>Override Autoscaling</NGLabelText>
            </NGSwitch>
          ) : null}
          {localOptions._hasAutoscaling || (!localOptions._hasAutoscaling && localOptions.overrideAutoscaling) ? (
            <>
              <div className="mt-4 mb-2 flex items-center gap-2">
                <NGLabel>Auto Scaling</NGLabel>
                <AutoScalingDoc />
              </div>
              <NGLabel>{localOptions.metric.label}</NGLabel>
              <div className="flex items-center mb-4">
                <NGSelect
                  style={{ width: 450 }}
                  onChange={(value) => draft.setMetric(value as any, index as any)}
                  options={localOptions.metric.options}
                  value={localOptions.metric.value}
                />
                <InfoTooltip
                  // TODO update for workload types other than serverless
                  title={[
                    `Scaling is determined by a specific metric, with the target being a replica.`,
                    `‘Disabled’ means there’s only one ‘Replica count’. In the API, the Min Scale and Max Scale are both set to the same specified value.`,
                    `'concurrency (Concurrent Requests)' uses the number of concurrent requests to the workload.`,
                    `'cpu (CPU Utilization)' uses % processor time utilized by the workload.`,
                    `'memory' memory in % for the target.`,
                    `'rps (Requests Per Second)' uses requests per second for the target.`,
                    `'Multi' allows you to scale with both CPU and Memory.`,
                    `The number of replicas for this deployment scales up/down in accordance with the chosen scaling strategy and values provided.`,
                  ]}
                />
                {draft.defaultOptions.metric.value !== localOptions.metric.value ? (
                  <RevertButton onClick={revert.metric} />
                ) : null}
              </div>
              {localOptions.metric.value === "disabled" ? null : (
                <>
                  {localOptions.metric.value === "latency" ? (
                    <>
                      <div className="flex items-end mb-4">
                        <div>
                          <NGLabel>{localOptions.metricPercentile.label}</NGLabel>
                          <NGSelect
                            style={{ width: 215, marginRight: 10 }}
                            onChange={localOptions.metricPercentile.setValue}
                            options={localOptions.metricPercentile.options}
                            value={localOptions.metricPercentile.value}
                          />
                        </div>
                        <div>
                          <NGInputAdapter
                            data={localOptions.target}
                            style={{
                              width: 225,
                            }}
                          />
                        </div>
                        {canRevertTargetMetricPercentile ? (
                          <RevertButton onClick={revert.targetMetricPercentile} shift />
                        ) : null}
                      </div>
                    </>
                  ) : localOptions.metric.value === "multi" ? (
                    <>
                      <div className="flex items-end mb-4 ">
                        <div>
                          <NGInputAdapter data={localOptions.multiCpuTarget} style={{ width: 215, marginRight: 10 }} />
                        </div>
                        <div>
                          <NGInputAdapter
                            data={localOptions.multiMemoryTarget}
                            style={{ width: 215, marginLeft: 10 }}
                          />
                        </div>
                        {canRevertMultiTargets ? <RevertButton onClick={revert.multiTargets} shift /> : null}
                      </div>
                    </>
                  ) : (
                    <div className="flex items-end mb-4">
                      <div>
                        <NGInputAdapter
                          data={localOptions.target}
                          style={{
                            width: 450,
                          }}
                        />
                      </div>
                      {canRevertTargetMetricPercentile ? (
                        <RevertButton onClick={revert.targetMetricPercentile} shift />
                      ) : null}
                    </div>
                  )}
                </>
              )}
              <div className="flex items-end mb-4">
                <div>
                  <NGInputAdapter
                    data={localOptions.minScale}
                    style={{ width: 450 }}
                    infoContents={[
                      `The minimum number of replicas.`,
                      `If set to zero, a new replica becomes active when a request is received, after a few seconds. It is recommended to set Min Scale to at least 2 replicas for production use cases when availability is paramount.`,
                    ]}
                    description="Minimum number of replicas."
                  />
                </div>
                {draft.defaultOptions.minScale.value !== localOptions.minScale.value ? (
                  <RevertButton onClick={revert.minScale} shift />
                ) : null}
              </div>
              {localOptions.metric.value === "disabled" ? null : (
                <div className="flex items-end mb-4">
                  <div>
                    <NGInputAdapter
                      data={localOptions.maxScale}
                      style={{ width: 450 }}
                      infoContents={["The maximum number of replicas."]}
                      description="The maximum number of replicas."
                    />
                  </div>
                  {draft.defaultOptions.maxScale.value !== localOptions.maxScale.value ? (
                    <RevertButton onClick={revert.maxScale} shift />
                  ) : null}
                </div>
              )}
              {localOptions.metric.value === "disabled" ? null : draft.isServerless || draft.isStandard ? (
                <div className="flex items-end mb-4">
                  <div>
                    <NGInputAdapter
                      data={localOptions.scaleToZeroDelay}
                      style={{ width: 450 }}
                      infoContents={[
                        `The number of seconds until a scale down event occurs. For example, if the number of concurrent requests drops to a level that warrants a scale down event, the number of replicas will be adjusted after the number of seconds specified by this value.`,
                        `The minimum value is 30 seconds.`,
                      ]}
                      description="Number of seconds (min. 30) before a scale down event."
                    />
                  </div>
                  {draft.defaultOptions.scaleToZeroDelay.value !== localOptions.scaleToZeroDelay.value ? (
                    <RevertButton onClick={revert.scaleToZeroDelay} shift />
                  ) : null}
                </div>
              ) : null}
              {draft.isServerless ? (
                <div className="flex items-end mb-4">
                  <div>
                    <NGInputAdapter
                      data={localOptions.maxConcurrency}
                      style={{ width: 450 }}
                      infoContents={[
                        `Maximum number of concurrent requests routed to a replica.`,
                        `If no replicas are available to fulfill the request, the request is queued until a replica is available. Either from existing requests having been completed, or from new replica(s) becoming available as a result of a scale up action.`,
                      ]}
                      description="Maximum number of concurrent requests routed to a replica."
                    />
                  </div>
                  {draft.defaultOptions.maxConcurrency.value !== localOptions.maxConcurrency.value ? (
                    <RevertButton onClick={revert.maxConcurrency} shift />
                  ) : null}
                </div>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export const LocalOptionsItem = observer(LocalOptionsItemRaw);
