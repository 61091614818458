import React from "react";
import { observer } from "mobx-react-lite";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Template } from "../types/template";
import { Theme } from "../../../mobxStores/uiData/theme";
import { NGChip } from "../../../newcomponents/chip";
import { getTemplateVersion, getVersions } from "../utils";
import { MARKETPLACE_SERVICE_URL } from "../../../envVariables";

interface Props {
  template: Template;
  unfiltered?: boolean;
}
const MarketplaceTemplateCardRaw: React.FC<Props> = ({ template, unfiltered }) => {
  const versions = getVersions(template);
  const templateVersion = getTemplateVersion(template, versions[versions.length - 1]);

  return (
    <>
      <Link
        to={`${template.name}`}
        className={clsx("template-card rounded-md cursor-pointer")}
        style={{
          minHeight: 180,
          backgroundColor: Theme.theme === "dark" ? "rgb(52, 66, 86)" : "rgb(253, 253, 253)",
          opacity: unfiltered ? 0.5 : undefined,
        }}
      >
        <div
          className="h-1/3 flex pt-4 pb-2 px-4 justify-between items-start gap-4 rounded-t-md keep-light"
          style={{ backgroundColor: "white" }}
        >
          <div
            className="h-full flex items-center justify-center"
            style={{
              width: "auto",
              maxWidth: 75,
              height: 30,
              backgroundColor: template.name === "pgedge" ? "#02062b" : undefined,
              paddingInline: template.name === "pgedge" ? 6 : undefined,
              borderRadius: template.name === "pgedge" ? 6 : undefined,
            }}
          >
            <img className="object-contain w-full h-full" src={`${MARKETPLACE_SERVICE_URL}/icon/${template.name}`} />
          </div>
          <NGChip variant={`info`} size="small" label={`v${templateVersion?.appVersion}`} />
        </div>
        <div className="h-2/3 flex pb-4 px-4 flex-col pt-2">
          <div className="text-lg font-semibold template-card-name">{template.name}</div>
          <div className="text-sm">{templateVersion?.description}</div>
        </div>
      </Link>
    </>
  );
};

export const MarketplaceTemplateCard = observer(MarketplaceTemplateCardRaw);
