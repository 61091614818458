import * as React from "react";
import { observer } from "mobx-react-lite";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { DIRECT_LB_IPSET_ENABLED } from "../../../envVariables";
import { NGInputListMst } from "../../../newcomponents/inputList/inputListMst";
import { NGInput } from "../../../newcomponents/input/input";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { inputTransforms } from "../../../mobxDataModels/transforms";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { NGInputAdapter } from "../../../newcomponents/input/inputAdapter";
import NGAlert from "../../../newcomponents/alert";

interface Props {
  workloadDraft: WorkloadDraftMobx;
}
const WorkloadCreateLoadBalancerRaw: React.FC<Props> = ({ workloadDraft }) => {
  const lb = workloadDraft.loadBalancer;
  const d = lb.direct;

  React.useEffect(() => {
    const lastItem = d.ports.items[d.ports.items.length - 1];
    if (!lastItem) {
      return;
    }
    if (!!lastItem.secondValue) {
      return;
    }
    lastItem.setSecondValue("TCP");
  }, [d.ports.items.length]);

  let geoHeadersError = "";
  if (lb.enabledGeoHeaders) {
    if (!lb.geoHeaderAsn.value && !lb.geoHeaderCity.value && !lb.geoHeaderCountry.value && !lb.geoHeaderRegion.value) {
      geoHeadersError = "At least one header must be set when geo headers are enabled";
    }
    const headers = [
      lb.geoHeaderAsn.value,
      lb.geoHeaderCity.value,
      lb.geoHeaderCountry.value,
      lb.geoHeaderRegion.value,
    ].filter(Boolean);
    const headersSet = new Set(headers);
    if (headers.length != headersSet.size) {
      geoHeadersError = "Headers must be unique";
    }
  }

  return (
    <>
      <div className="p-4 border rounded w-min mb-4">
        <div className={`flex items-center ${d.enabled ? "mb-4" : ""}`} style={{ width: 900 }}>
          <NGSwitch value={d.enabled} onChange={d.setEnabled}>
            <NGLabelText>Enable Direct Load Balancer</NGLabelText>
          </NGSwitch>
          <InfoTooltip
            title={[
              `Load Balancer charges will be accrued for each location.`,
              `The standard workload endpoints will route traffic to the Direct Workload Load Balancer.`,
              `Any required certificates must be provided by the workload at runtime.`,
            ]}
          />
        </div>
        {d.enabled ? (
          <>
            {DIRECT_LB_IPSET_ENABLED ? (
              <NGFormElement label={"Ip Set"} name={"ipSet"} value={d.ipSet} onChange={d.setIpSet} />
            ) : null}
            <NGInputListMst
              label="Ports"
              data={d.ports}
              firstLabel="External Port"
              firstInput={(i) => (
                <NGInput
                  value={i.firstValue}
                  // TODO improve transforms and validations
                  onChange={(e) => i.setFirstValue(inputTransforms.number[0](e.target.value))}
                  required
                  invalid={
                    !i.firstValue ||
                    Number.isNaN(Number(i.firstValue)) ||
                    Number(i.firstValue) < 22 ||
                    Number(i.firstValue) > 32768
                  }
                />
              )}
              secondLabel="Protocol"
              secondInput={(i) => (
                <NGSelect
                  value={i.secondValue}
                  onChange={i.setSecondValue}
                  options={[
                    { label: "TCP", value: "TCP" },
                    { label: "UDP", value: "UDP" },
                  ]}
                />
              )}
              thirdLabel="Container Port"
              thirdInput={(i) => (
                <NGInput
                  value={i.thirdValue}
                  onChange={(e) => i.setThirdValue(inputTransforms.number[0](e.target.value))}
                  invalid={
                    !!i.thirdValue &&
                    (Number.isNaN(Number(i.thirdValue)) ||
                      Number(i.thirdValue) < 80 ||
                      Number(i.thirdValue) > 65535 ||
                      [8012, 8022, 9090, 9091, 15000, 15001, 15006, 15020, 15021, 15090, 41000].includes(
                        Number(i.thirdValue),
                      ))
                  }
                />
              )}
              fourthLabel="Scheme"
              fourthInput={(i) => (
                <NGSelect
                  value={i.fourthValue}
                  onChange={i.setFourthValue}
                  options={[
                    { label: "http", value: "http" },
                    { label: "tcp", value: "tcp" },
                    { label: "https", value: "https" },
                    { label: "ws", value: "ws" },
                    { label: "wss", value: "wss" },
                  ]}
                />
              )}
              invalid={!d.isPortsValid}
              error={d.portsError}
            />
          </>
        ) : null}
      </div>
      <div className="p-4 border rounded w-min">
        <div className={`flex items-center ${lb.enabledGeoHeaders ? "mb-4" : ""}`} style={{ width: 450 }}>
          <NGSwitch value={lb.enabledGeoHeaders} onChange={lb.setEnabledGeoHeaders}>
            <NGLabelText>Enable Geo Headers</NGLabelText>
          </NGSwitch>
        </div>
        {lb.enabledGeoHeaders ? (
          <>
            <NGInputAdapter
              className="mb-4"
              style={{ width: 450 }}
              data={lb.geoHeaderAsn}
              infoContents={["The geo asn header."]}
            />
            <NGInputAdapter
              className="mb-4"
              style={{ width: 450 }}
              data={lb.geoHeaderCity}
              infoContents={["The geo city header."]}
            />
            <NGInputAdapter
              className="mb-4"
              style={{ width: 450 }}
              data={lb.geoHeaderCountry}
              infoContents={["The geo country header."]}
            />
            <NGInputAdapter
              className="mb-4"
              style={{ width: 450 }}
              data={lb.geoHeaderRegion}
              infoContents={["The geo region header."]}
            />
          </>
        ) : null}
        {geoHeadersError ? <NGAlert type={"error"} title={geoHeadersError} /> : null}
      </div>
    </>
  );
};

export const WorkloadCreateLoadBalancer = observer(WorkloadCreateLoadBalancerRaw);
