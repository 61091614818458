import React from "react";
import { observer } from "mobx-react-lite";
import { Layout } from "../../layout";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { NGLabel } from "../../newcomponents/text/label";

const CreditsRoute: React.FC = () => {
  const { org } = ConsoleContext;

  return (
    <Layout key={org} showLeftMenu={false}>
      <div>
        <NGLabel className="mb-8">Credits</NGLabel>
        <NGLabel className="mb-4">Icons</NGLabel>
        <div className="flex flex-col gap-1">
          <a href="https://www.flaticon.com/free-icons/ui-design" title="ui design icons" target={"_blank"}>
            Ui design icons created by paonkz - Flaticon
          </a>
          <a href="https://www.flaticon.com/free-icons/app-store" title="app store icons" target={"_blank"}>
            App store icons created by juicy_fish - Flaticon
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default observer(CreditsRoute);
