import * as React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { mk8sDryRun } from "../../../../../mobxStores/dryRun/mk8s";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { homeLink, request } from "../../../../../services/cpln";
import { DryRunAlert } from "../../../dryRunAlert";
import { NGButton } from "../../../../../newcomponents/button/Button";
import { NGFormElement } from "../../../../../newcomponents/ngformelement/ngformelement";
import { MoreHorizontal } from "react-feather";
import { NGProviderBrowser } from "../../../ngProviderBrowser";
import { useNGFormContext } from "../../../../../reactContexts/ngFormContext";
import { NGInputListMst } from "../../../../../newcomponents/inputList/inputListMst";
import { NGInput } from "../../../../../newcomponents/input/input";
import { NGSelect } from "../../../../../newcomponents/select/ngselect";
import { getDryRunErrorText } from "../../../getDryRunErrorText";

type RouteParams = "index";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderLinodeNodePoolRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [browserKey, setBrowserKey] = React.useState("");
  const formData = useNGFormContext();

  const { index: indexStr } = useParams<RouteParams>();
  const index = Number(indexStr);

  const l = mk8sDraft.provider_linode;
  const np = l.nodePools.find((np) => np.index === index);

  if (!np) {
    return <Navigate to={`${pathname.split("/-node-pools")[0]}/-node-pools`} />;
  }

  const pre = `linode.nodePools[${index}].`;

  const labelsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.linode.nodePools[${index}].labels`,
    paths: np.labels.items.map((i) => `spec.provider.linode.nodePools[${index}].labels.${i.firstValue}`),
  });
  const taintsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.linode.nodePools[${index}].taints`,
    paths: [
      ...np.taints.items.map((_, idx) => `spec.provider.linode.nodePools[${index}].taints[${idx}]`),
      ...np.taints.items.map((_, idx) => `spec.provider.linode.nodePools[${index}].taints[${idx}].key`),
      ...np.taints.items.map((_, idx) => `spec.provider.linode.nodePools[${index}].taints[${idx}].value`),
      ...np.taints.items.map((_, idx) => `spec.provider.linode.nodePools[${index}].taints[${idx}].effect`),
    ],
  });

  return (
    <>
      <div>
        <NGFormElement
          name={`${pre}name`}
          label={np.name.label}
          value={np.name.value}
          onChange={np.name.setValue}
          required
          error={!l.isNodePoolNameValid(np.index) ? "Node pool names must be unique" : ""}
        />

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}serverType`}
            label={np.serverType.label}
            value={np.serverType.value}
            onChange={np.serverType.setValue}
            required={np.serverType.isRequired}
            error={np.serverType.error}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => setBrowserKey("server-types"),
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get(`${pre}serverType`).touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.serverType.setValue}
              path={`spec.provider.linode.nodePools[${index}].serverType`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}overrideImage`}
            label={np.overrideImage.label}
            value={np.overrideImage.value}
            onChange={np.overrideImage.setValue}
            required={np.overrideImage.isRequired}
            error={np.overrideImage.error}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => setBrowserKey("images"),
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get(`${pre}overrideImage`).touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.overrideImage.setValue}
              path={`spec.provider.linode.nodePools[${index}].overrideImage`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}subnetId`}
            label={np.subnetId.label}
            value={np.subnetId.value}
            onChange={np.subnetId.setValue}
            required={np.subnetId.isRequired}
            error={np.subnetId.error}
            innerButtons={[
              { title: "Browse", onClick: () => setBrowserKey("subnets"), render: () => <MoreHorizontal /> },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get(`${pre}subnetId`).touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.subnetId.setValue}
              path={`spec.provider.linode.nodePools[${index}].subnetId`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}minSize`}
            label={np.minSize.label}
            value={np.minSize.value}
            onChange={np.minSize.setValue}
            required={np.minSize.isRequired}
            error={np.minSize.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get(`${pre}minSize`).touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.minSize.setValue}
              path={`spec.provider.linode.nodePools[${index}].minSize`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}maxSize`}
            label={np.maxSize.label}
            value={np.maxSize.value}
            onChange={np.maxSize.setValue}
            required={np.maxSize.isRequired}
            error={np.maxSize.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get(`${pre}maxSize`).touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.maxSize.setValue}
              path={`spec.provider.linode.nodePools[${index}].maxSize`}
            />
          </div>
        </div>
        <NGInputListMst
          data={np.labels}
          className="mb-8"
          label="Labels"
          firstLabel="Key"
          firstInput={(i) => {
            let invalid = false;
            if (!i.firstValue) {
              invalid = true;
            }
            if (i.firstValue && labelsDryRun.message.includes(`.${i.firstValue}`)) {
              invalid = true;
            }

            return (
              <NGInput
                required
                invalid={invalid}
                value={i.firstValue}
                onChange={(e) => i.setFirstValue(e.target.value)}
              />
            );
          }}
          secondLabel="Value"
          secondInput={(i) => {
            let invalid = false;
            if (!i.secondValue) {
              invalid = true;
            }

            return (
              <NGInput
                required
                invalid={invalid}
                value={i.secondValue}
                onChange={(e) => i.setSecondValue(e.target.value)}
              />
            );
          }}
          invalid={labelsDryRun.severity === "error"}
          error={labelsDryRun.severity === "error" ? labelsDryRun.message : ""}
          warning={labelsDryRun.severity === "error" ? "" : labelsDryRun.message}
        />
        <NGInputListMst
          data={np.taints}
          className="mb-8"
          label="Taints"
          firstLabel="Key"
          firstInput={(i, index) => {
            let invalid = false;
            if (!i.firstValue) {
              invalid = true;
            }
            if (i.firstValue && taintsDryRun.message.includes(`[${index}].key`)) {
              invalid = true;
            }

            return (
              <NGInput
                required
                invalid={invalid}
                value={i.firstValue}
                onChange={(e) => i.setFirstValue(e.target.value)}
              />
            );
          }}
          secondLabel="Value"
          secondInput={(i) => {
            let invalid = false;
            if (!i.secondValue) {
              invalid = true;
            }
            if (i.secondValue && taintsDryRun.message.includes(`[${index}].value`)) {
              invalid = true;
            }

            return (
              <NGInput
                placeholder="Value"
                required
                invalid={invalid}
                value={i.secondValue}
                onChange={(e) => i.setSecondValue(e.target.value)}
              />
            );
          }}
          thirdLabel="Effect"
          thirdInput={(i) => {
            let invalid = false;
            if (i.thirdValue && taintsDryRun.message.includes(`[${index}].effect`)) {
              invalid = true;
            }

            return (
              <NGSelect
                invalid={invalid}
                value={i.thirdValue}
                onChange={(value) => i.setThirdValue(value)}
                options={[
                  { label: "NoSchedule", value: "NoSchedule" },
                  { label: "PreferNoSchedule", value: "PreferNoSchedule" },
                  { label: "NoExecute", value: "NoExecute" },
                ]}
              />
            );
          }}
          invalid={taintsDryRun.severity === "error"}
          error={taintsDryRun.severity === "error" ? taintsDryRun.message : ""}
          warning={taintsDryRun.severity === "error" ? "" : taintsDryRun.message}
        />
        <NGButton
          style={{ width: 220 }}
          variant={"danger"}
          outlined
          onClick={() => {
            l.removeNodePoolAt(np.index);
            navigate(`${pathname.split("/-node-pools")[0]}/-node-pools`);
          }}
        >
          Delete Node Pool
        </NGButton>
      </div>
      {browserKey === "server-types" ? (
        <NGProviderBrowser
          // TODO pagination for all discovery endpoints with pages
          title="Browse Linode Server Types"
          initialValue={np.serverType.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "server-types",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.data;
          }}
          sortable
          onOk={(value) => np.serverType.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Cores", "Memory", "Disk", "GPUs", "Cost per Month"]}
          rightAlign={["Cost per Month"]}
          distinctFilters={["Cores", "Memory", "Disk", "GPUs"]}
          getData={(item: any) => {
            let priceObject = item.region_prices.find((rp) => rp.id === l.region.value);
            if (!priceObject) {
              priceObject = item.price;
            }
            const price = priceObject.monthly;
            const cost = price.toLocaleString("en-US", { style: "currency", currency: "USD" });

            let memoryAmount = item.memory;
            let memoryUnit = "MB";
            if (memoryAmount >= 1024 * 10) {
              memoryAmount /= 1024;
              memoryUnit = "GB";
            }

            let diskAmount = item.disk;
            let diskUnit = "MB";
            if (diskAmount >= 1024 * 10) {
              diskAmount /= 1024;
              diskUnit = "GB";
            }

            return [
              //
              item.id,
              item.label,
              item.vcpus,
              [item.memory, `${memoryAmount}${memoryUnit}`],
              [item.disk, `${diskAmount}${diskUnit}`],
              item.gpus,
              [price, cost],
            ];
          }}
        />
      ) : null}
      {browserKey === "images" ? (
        <NGProviderBrowser
          title="Browse Linode Images"
          initialValue={np.overrideImage.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "images",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.data;
          }}
          onOk={(value) => np.overrideImage.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Description"]}
          getData={(item: any) => [item.id, item.id, item.label]}
        />
      ) : null}
      {browserKey === "subnets" ? (
        <NGProviderBrowser
          title="Browse Linode Subnets"
          initialValue={np.subnetId.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "subnets",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.data;
          }}
          onOk={(value) => np.subnetId.setValue(String(value as string))}
          onClose={() => setBrowserKey("")}
          labels={["Label", "Id", "IPv4"]}
          getData={(item: any) => [item.id, item.label, item.id, item.ipv4]}
        />
      ) : null}
    </>
  );
};

export const Mk8sCreateProviderLinodeNodePool = observer(Mk8sCreateProviderLinodeNodePoolRaw);
