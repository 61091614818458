import * as React from "react";
import NGAlert from "../../../newcomponents/alert";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { Download, Edit2, MinusCircle, Upload } from "react-feather";
import { OtherEnvironmentVariables } from "./otherEnvironmentVariables";
import { STORAGE_KEY_DEFAULT_VARIABLES } from "../../../envVariables";
import { EnvVarsMobx } from "../../../mobxDataModels/envVarsModel";
import { FormButtons } from "../../../components/forms/formButtons";
import { parseEnvFile } from "../../../services/utils";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { EnvironmentVariable } from "./environmentVariable";
import { NGLabel } from "../../../newcomponents/text/label";
import { Table } from "../../../newcomponents/table/table";
import { EnvVarMobx, EnvVarModel } from "../../../mobxDataModels/envVarModel";
import { CopyableTruncateTooltip } from "../../../components/CopyableTruncateTooltip";

export interface EnvVarTableItem {
  key: string;
  value: string;
  mobx: EnvVarMobx;
}

export interface EnvVarInherit {
  isActive: boolean;
  env: { name: string; value: string }[];
  value: boolean;
  setValue: any;
}

interface Props {
  env_version?: number;
  env_image?: string;
  env_gvc?: string;
  env_gvcAlias?: string;
  env_org?: string;
  env_workload?: string;
  env: EnvVarsMobx;
  filename: string;
  hasDefaultEnv: boolean;
  inherit?: EnvVarInherit;
  saveForm?: {
    isActive: boolean;
    onReset: any;
    onSave: any;
    isLoading: boolean;
    isDirty: boolean;
  };
}

const EnvironmentVariablesRaw: React.FC<Props> = ({
  env_version,
  env_image,
  env_gvc,
  env_gvcAlias,
  env_org,
  env_workload,
  env,
  filename,
  hasDefaultEnv,
  inherit = {
    isActive: false,
    env: [],
    value: false,
    setValue: () => {},
  },
  saveForm = {
    isActive: false,
  },
}) => {
  const defaultEnvVariables = [
    {
      name: "CPLN_GLOBAL_ENDPOINT", // full url, but the given domain can be different for each user or workload in prod
      value: "@runtime",
    },
    {
      name: "CPLN_GVC", // gvc name
      value: env_gvc || "@runtime",
    },
    {
      name: "CPLN_GVC_ALIAS",
      value: env_gvcAlias || "@runtime",
    },
    {
      name: "CPLN_LOCATION", //*
      value: "@runtime",
    },
    {
      name: "CPLN_NAMESPACE", //*
      value: "@runtime",
    },
    {
      name: "CPLN_PROVIDER", //*
      value: "@runtime",
    },
    {
      name: "CPLN_ORG", // org link
      value: env_org || "@runtime",
    },
    {
      name: "CPLN_WORKLOAD", // workload link
      value: env_workload || "@runtime",
    },
    {
      name: "CPLN_WORKLOAD_VERSION",
      value: String(env_version) || "@runtime",
    },
    {
      name: "CPLN_TOKEN",
      value: "@runtime",
    },
    {
      name: "CPLN_IMAGE",
      value: env_image || "@runtime",
    },
  ];

  // States
  const [envVar, setEnvVar] = React.useState<EnvVarMobx>(env.new());
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [importOverrides, setImportOverrides] = React.useState<string[]>([]);
  const [showDefaultVariables, setShowDefaultVariables] = React.useState<boolean>(getShowDefaultVariables());

  // Effects
  React.useEffect(() => {
    localStorage.setItem(STORAGE_KEY_DEFAULT_VARIABLES, String(showDefaultVariables));
  }, [showDefaultVariables]);

  React.useEffect(() => {
    if (!isUpdating) {
      return;
    }

    let found: boolean = false;

    for (const editVar of env.editVars) {
      if (editVar.index === envVar.index) {
        found = true;
      }
    }

    if (found) {
      return;
    }

    onCancelEdit();
  }, [env.editVars.length]);

  // Functions
  function getShowDefaultVariables(): boolean {
    return localStorage.getItem(STORAGE_KEY_DEFAULT_VARIABLES) === "true";
  }

  async function onExport(): Promise<void> {
    try {
      let blob = ``;
      for (let defaultVar of env.editVars) {
        blob += `${defaultVar.name}=${defaultVar.value}\n`;
      }
      const a = document.createElement("a");
      a.style.display = "none";
      a.classList.add("cpln-temp-a");
      a.download = `${filename}.env`;
      const file = new Blob([blob], { type: "text/plain" });
      const href = URL.createObjectURL(file);
      a.href = href;
      a.click();
    } catch (e) {
      notification.warning({ message: "Failed", description: "Try again" });
    }
  }

  function onImport(e: any): void {
    const input: HTMLInputElement = e.target;
    if (!input.value || !input.files || input.files.length < 1) {
      input.value = "";
      return;
    }

    const file = input.files[0];
    if (!file) {
      input.value = "";
      notification.warning({
        message: "Failed",
        description: "File is invalid",
      });
      return;
    }

    var reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = function (evt) {
      try {
        const content: string = evt.target?.result as string;
        const rawVars = Object.entries(parseEnvFile(content)).map(([name, value]) => ({ name, value }));
        const overriddenKeys = env.addFromFile(rawVars);
        setImportOverrides(overriddenKeys);
      } catch (e) {
        console.error(e.message);
        notification.warning({
          message: "Failed",
          description: "File is invalid",
        });
        input.value = "";
      }
    };
    reader.onerror = function () {
      input.value = "";
      notification.warning({
        message: "Failed",
        description: "File is invalid",
      });
    };
    input.value = "";
  }

  function onAdd(): void {
    env.addEnvVar(envVar);
    setEnvVar(env.new());
  }

  function onEdit(editEnvVar: EnvVarMobx): void {
    setIsUpdating(true);
    setEnvVar(EnvVarModel.create({ ...editEnvVar }));
  }

  function onUpdate(): void {
    for (const editVar of env.editVars) {
      if (editVar.index === envVar.index) {
        editVar.setMetadata(envVar.metadata);
        editVar.setName(envVar.name);
        editVar.setValuePrefix(envVar.valuePrefix);
        editVar.setValueBody(envVar.valueBody);
        editVar.setValueSuffix(envVar.valueSuffix);

        if (editVar.status === "default") {
          editVar.setStatus("changed");
        }

        onCancelEdit();
        return;
      }
    }
  }

  function onCancelEdit(): void {
    setEnvVar(env.new());
    setIsUpdating(false);
  }

  // Computed
  const envVarsList: EnvVarTableItem[] = env.editVars.map((editVar) => {
    let value: string = "";

    // Prefix
    if (editVar.valuePrefix !== "text") {
      value += editVar.valuePrefix;
    }

    // Body
    value += editVar.valueBody;

    // Suffix
    if (editVar.valueSuffix) {
      value += `.${editVar.valueSuffix}`;
    }

    return {
      key: editVar.name,
      value: value,
      mobx: editVar,
    };
  });

  return (
    <>
      {/* Header */}
      <div className="flex items-center gap-5 justify-between mb-4 w-full">
        <div className="text-2xl">Environment Variables</div>
        {/* TODO disabled style, tooltip to inform why disabled */}
        <div className="flex items-center gap-2">
          {inherit.isActive ? (
            <div className="flex items-center mr-3">
              <NGSwitch value={inherit.value} onChange={(checked) => inherit.setValue(checked)}>
                <NGLabelText>Inherit GVC</NGLabelText>
              </NGSwitch>
              <InfoTooltip title={["Appends the environment variables defined in the GVC to this workload."]} />
            </div>
          ) : null}
          {hasDefaultEnv ? (
            <div className="flex items-center mr-3">
              <NGSwitch value={showDefaultVariables} onChange={(checked) => setShowDefaultVariables(checked)}>
                <NGLabelText>Show Defaults</NGLabelText>
              </NGSwitch>
              <InfoTooltip
                title={[
                  "Each workload is injected with environment variables containing Control Plane Platform-related information. Enable this option to view the details on this page.",
                ]}
              />
            </div>
          ) : null}
          <NGButton
            variant="secondary"
            outlined
            size="small"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                document.getElementById("envFile")?.click();
              }
            }}
            renderIcon={(hover, props) => <Download {...props} />}
            onClick={() => document.getElementById("envFile")?.click()}
            style={{ height: 30 }}
          >
            Import
            <input
              id="envFile"
              className="invisible"
              style={{ width: 0, height: 0 }}
              onChange={onImport}
              type={"file"}
              multiple={false}
            />
          </NGButton>
          <NGButton
            variant="secondary"
            outlined
            size="small"
            renderIcon={(hover, props) => <Upload {...props} />}
            aria-disabled={env.vars.length === 0}
            disabled={env.vars.length === 0}
            onClick={onExport}
            style={{ height: 30 }}
          >
            Export
          </NGButton>
        </div>
      </div>
      {/* Table & Form */}
      <Table<EnvVarTableItem>
        hideSettings
        maxHeight={565}
        data={envVarsList}
        tableId={"envVars"}
        columns={[
          {
            id: "key",
            label: "Key",
            enableResize: true,
            cell: (cellProps) => (
              <CopyableTruncateTooltip
                value={cellProps.renderValue()}
                overrideValueToCopy={() => `$(${cellProps.renderValue()})`}
              />
            ),
          },
          {
            id: "value",
            label: "Value",
            enableResize: true,
            cell: (cellProps) => <CopyableTruncateTooltip value={cellProps.renderValue()} />,
          },
          {
            id: "actions",
            label: "Actions",
            size: 120,
            cell: (cellProps) => (
              <div className="flex gap-2 py-1">
                <NGButton
                  variant="secondary"
                  text
                  renderIcon={(_, props) => <Edit2 {...props} />}
                  onClick={() => onEdit(cellProps.row.original.mobx)}
                />
                <NGButton
                  variant="danger"
                  text
                  renderIcon={(_, props) => <MinusCircle {...props} />}
                  onClick={() => env.remove(cellProps.row.original.mobx.index)}
                />
              </div>
            ),
          },
        ]}
      />
      <NGLabel className="mt-4 mb-2">{isUpdating ? "Editing" : "Add"} Environment Variable</NGLabel>
      <EnvironmentVariable
        env={env}
        envVar={envVar}
        inherit={inherit}
        isUpdating={isUpdating}
        onAdd={onAdd}
        onUpdate={onUpdate}
        onCancelEdit={onCancelEdit}
      />

      {/* Alert: Import Overrides */}
      {importOverrides.length > 0 ? (
        <NGAlert
          type="warning"
          className="mt-4"
          title="Some Variables Were Overridden"
          message="The following environment variables were overridden due to custom values specified during the import process:"
          style={{ maxWidth: "100%", width: "100%" }}
          closable
          onClose={() => setImportOverrides([])}
          render={() => (
            <ul className="flex flex-col gap-2 list-disc list-inside">
              {importOverrides.map((envVarKey, index) => (
                <li key={envVarKey + String(index)}>{envVarKey}</li>
              ))}
            </ul>
          )}
        />
      ) : null}

      {/* Alert: Force redeployment of workloads */}
      {saveForm.isActive && saveForm.isDirty ? (
        <NGAlert
          type="info"
          className="mt-4"
          message="Any change will initiate the deployment of workloads that have the 'inheritEnv' flag enabled in any of their containers"
        />
      ) : null}

      {/* Inherited Environment Variables */}
      {inherit.isActive && inherit.value ? (
        <div className="mt-10">
          <div className="text-xl mb-2">
            Inherited <span className="text-sm">- You can override by using a custom variable with the same name.</span>
          </div>
          {inherit.env.length < 1 ? (
            <div className={"no-items-found"}>
              <span>No Environment Variables to Inherit</span>
            </div>
          ) : (
            <OtherEnvironmentVariables env={inherit.env} />
          )}
        </div>
      ) : null}

      {/* Default Environment Variables */}
      {hasDefaultEnv && showDefaultVariables ? (
        <div className="mt-10">
          <div className="text-xl mb-2">Default</div>
          <OtherEnvironmentVariables env={defaultEnvVariables} ignoreCopyOfValue="@runtime" />
        </div>
      ) : null}

      {/* Save Form */}
      {saveForm.isActive ? (
        <FormButtons
          onReset={saveForm.onReset}
          onSave={saveForm.onSave}
          resetDisabled={saveForm.isLoading || !saveForm.isDirty}
          saveDisabled={saveForm.isLoading || !saveForm.isDirty}
          loading={saveForm.isLoading}
        />
      ) : null}
    </>
  );
};

export const EnvironmentVariables = observer(EnvironmentVariablesRaw);
