import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { BasePathContext } from "../../../reactContexts/basePathContext";
import { NGFormLabel } from "../../../newcomponents/text/formLabel";
import { CodeEditor } from "../../group/identityMatcher/codeEditor";
import { NGButton } from "../../../newcomponents/button/Button";
import { Modal, notification } from "antd";
import { request } from "../../../services/cpln";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { Loader } from "../../../components/layout/loader";
import NGAlert from "../../../newcomponents/alert";
import { NameModel } from "../../../mobxDataModels/nameModel";
import jsYaml from "js-yaml";
import qs from "qs";
import "./templateInstall.css";
import { StringModel } from "../../../mobxDataModels/stringModel";
import { NGFormContext } from "../../../reactContexts/ngFormContext";
import { NGFormData } from "../../../mobxStores/ngFormData";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { NGLabel } from "../../../newcomponents/text/label";
import { Copy, Download } from "react-feather";
import { Template } from "../types/template";
import { k8sKeySort } from "../../../services/utils";
import { getTemplateVersion, getVersions } from "../utils";
import { FilesReducerActionType, useFilesReducer } from "./filesReducer";
import { CodeEditorModal } from "../../../components/modals/codeEditorModal";
import { MARKETPLACE_SERVICE_URL } from "../../../envVariables";
import { UserData } from "../../../mobxStores/userData/userData";
import { isEqual } from "lodash";
import { NGChip } from "../../../newcomponents/chip";

type RouteParams = "template";
const MarketplaceTemplateInstallRaw: React.FC = () => {
  const { template: templateName } = useParams<RouteParams>();

  const formDataRef = React.useRef(new NGFormData());

  const basePath = React.useContext(BasePathContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [template, setTemplate] = React.useState<Template>(null as any);
  const [isLoadingInstall, setIsLoadingInstall] = React.useState(false);

  const versions = getVersions(template);

  const [defaultValues, setDefaultValues] = React.useState("");
  const [values, setValues] = React.useState("");

  const [isTemplateView, setIsTemplateView] = React.useState(false);
  const [helmTemplates, setHelmTemplates] = React.useState<any[]>([]);

  const nameRef = React.useRef(NameModel.create());
  const [version, setVersion] = React.useState("");
  const gvcRef = React.useRef(
    StringModel.create({
      label: "GVC",
      isRequired: true,
      validationKey: "name",
      initialValue: ConsoleContext.gvc || "",
    }),
  );

  const templateVersion = getTemplateVersion(template, version);

  const [redirect, setRedirect] = React.useState("");
  const [paramName, setParamName] = React.useState("");
  const [paramGvc, setParamGvc] = React.useState("");
  const [paramVersion, setParamVersion] = React.useState("");

  const [filesState, filesDispatch] = useFilesReducer();
  const [codeEditorOpenProperty, setCodeEditorOpenProperty] = React.useState("");

  React.useEffect(() => {
    processQueryParams();
    fetchTemplate();
  }, []);

  function processQueryParams() {
    const { redirect, gvc, name, version } = qs.parse(location.search, { ignoreQueryPrefix: true });

    setRedirect((redirect as string) || "");
    setParamName((name as string) || "");
    setParamGvc((gvc as string) || "");
    setParamVersion((version as string) || "");
  }

  async function fetchTemplate() {
    try {
      setIsLoading(true);
      setError("");
      const { data } = await request({ service: "marketplace", url: `/template/${templateName}` });
      setTemplate(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      setError(errorMessage);
    }
  }

  React.useEffect(() => {
    if (!template) {
      return;
    }

    if (!!paramVersion && versions.includes(paramVersion)) {
      setVersion(paramVersion);
    } else {
      setVersion(versions[versions.length - 1]);
    }
  }, [template]);

  React.useEffect(() => {
    if (!version) {
      return;
    }
    if (!versions.includes(version)) {
      return;
    }
    const fileDefaults: { [_: string]: string } = {};
    for (const [property, value] of Object.entries(template.versions[version].files || {})) {
      fileDefaults[property] = value.content;
    }

    filesDispatch({
      type: FilesReducerActionType.SET_DEFAULTS,
      payload: { content: "", property: "", defaults: fileDefaults },
    });
    const values = template.versions[version].values;
    setDefaultValues(values);
  }, [version]);

  React.useEffect(() => {
    if (!defaultValues) {
      return;
    }
    filesDispatch({
      type: FilesReducerActionType.RESET_TO_DEFAULTS,
      payload: { content: "", property: "" },
    });
    setValues(defaultValues);
  }, [defaultValues]);

  function getProcessedValues(): string {
    let processedValues = values;
    if (!templateVersion) {
      return processedValues;
    }
    if (templateVersion.files && Object.keys(templateVersion.files).length > 0) {
      for (let fileProperty of Object.keys(templateVersion.files)) {
        // TODO for handling multi files
        // const fileInfo = templateVersion.chart.files[fileProperty];
        const fileValue = filesState.current[fileProperty] || "";

        const indentCount = 2;
        const indentedFileValue = fileValue
          .split("\n")
          .map((line) => " ".repeat(indentCount) + line)
          .join("\n");

        processedValues += "\n" + fileProperty + ": |- \n" + indentedFileValue;
      }
    }
    return processedValues;
  }

  async function handleInstall() {
    try {
      setIsLoadingInstall(true);
      await request({
        service: "marketplace",
        method: "post",
        url: `/helm/install`,
        body: {
          org: ConsoleContext.org,
          gvc: paramGvc || gvcRef.current.value,
          name: paramName || nameRef.current.value,
          template: templateName,
          version: version,
          values: getProcessedValues(),
        },
      });
      notification.success({ message: "Success", description: "Installed template" });
      setIsLoadingInstall(false);
      UserData.requestHasWorkload();
      if (redirect) {
        navigate(`${basePath}/${redirect}`);
      } else {
        navigate(`${basePath}/release/${paramName || nameRef.current.value}`);
      }
    } catch (e) {
      setIsLoadingInstall(false);
      let errorMessage = e.response?.data?.message;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      if (errorMessage.startsWith("Command failed: ")) {
        errorMessage = `[` + errorMessage.split(`[`)[1];
      }
      notification.warning({ message: "Failed", description: errorMessage });
    }
  }

  async function handleTemplate() {
    try {
      setIsLoadingInstall(true);
      const { data } = await request({
        service: "marketplace",
        method: "post",
        url: `/helm/template`,
        body: {
          org: ConsoleContext.org,
          gvc: paramGvc || gvcRef.current.value,
          name: paramName || nameRef.current.value,
          template: templateName,
          version: version,
          values: getProcessedValues(),
        },
      });
      setHelmTemplates(jsYaml.loadAll(data.message));
      setIsTemplateView(true);
      notification.success({ message: "Success", description: "Templated" });
      setIsLoadingInstall(false);
    } catch (e) {
      setIsLoadingInstall(false);
      setHelmTemplates([]);
      let errorMessage = e.response?.data?.message;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      if (errorMessage.startsWith("Command failed: ")) {
        errorMessage = `[` + errorMessage.split(`[`)[1];
      }
      notification.warning({ message: "Failed", description: errorMessage });
    }
  }

  if (error) {
    return <NGAlert type={"error"} title={"Failed to fetch template values"} message={error} />;
  }

  if (!template || !version || isLoading) {
    return (
      <div className="relative">
        <Loader
          reason="Fetching template or values"
          style={{
            width: 100,
            height: 100,
            margin: 0,
            left: `calc(50% - 50px)`,
            right: "unset",
            top: 150,
            bottom: "unset",
          }}
        />
      </div>
    );
  }

  const filesAreDirty = !isEqual(filesState.current, filesState.defaults);

  return (
    <NGFormContext.Provider value={formDataRef.current}>
      <div style={{ maxWidth: 900 }}>
        <div className="header-inputs">
          <div className="flex items-center mb-4">
            <div style={{ width: 50 }}>
              <img className="object-fit" src={`${MARKETPLACE_SERVICE_URL}/icon/${templateName}`} />
            </div>
            <div className="flex flex-col ml-4">
              <span className="leading-none text-lg font-semibold">{template.name}</span>
              <span className="leading-none text-sm">{templateVersion?.category || ""}</span>
            </div>
            <div className="flex flex-col ml-4">
              <NGChip size={"small"} variant="info" label={`App v${templateVersion?.appVersion}`} />
            </div>
          </div>
          <div className="flex items-start">
            <div style={{ width: 289, marginRight: 16 }}>
              {paramGvc ? (
                <>
                  <NGFormLabel>GVC</NGFormLabel>
                  <div className="mb-4">{paramGvc}</div>
                </>
              ) : (
                <NGFormElement
                  label={`GVC`}
                  name={`gvc`}
                  required
                  value={gvcRef.current.value}
                  onChange={gvcRef.current.setValue}
                  as={"kindselect"}
                  kind="gvc"
                />
              )}
            </div>
            <div style={{ width: 289, marginRight: 16 }}>
              {paramName ? (
                <>
                  <NGFormLabel>Release Name</NGFormLabel>
                  <div className="mb-4">{paramName}</div>
                </>
              ) : (
                <NGFormElement
                  label={`Release Name`}
                  name={`name`}
                  required
                  value={nameRef.current.value}
                  onChange={nameRef.current.setValue}
                />
              )}
            </div>
            <div style={{ width: 290 }}>
              <NGFormElement
                label={`Template Version`}
                name={`version`}
                required
                as={"select"}
                options={versions.map((v, index) => {
                  const lastElement = index === versions.length - 1;
                  return { label: `${v}${lastElement ? ` (Latest)` : ""}`, value: v };
                })}
                value={version}
                onChange={setVersion}
              />
            </div>
          </div>
        </div>
        <NGLabel>Values</NGLabel>
        <div className="text-sm">
          <CodeEditor language="yaml" options={{}} value={values} setValue={setValues} />
        </div>
        {templateVersion ? (
          Object.keys(templateVersion.files || {}).length < 1 ? null : (
            <>
              {Object.entries(templateVersion.files || {}).map(([fileProperty, fileInfo]) => {
                if (fileInfo.type === "multi") {
                  return null;
                }

                const content = filesState.current[fileProperty];

                return (
                  <div className="my-4">
                    {content ? (
                      <div>
                        <NGLabel>{fileInfo.label}</NGLabel>
                        <NGButton variant={"secondary"} onClick={() => setCodeEditorOpenProperty(fileProperty)}>
                          Edit
                        </NGButton>
                      </div>
                    ) : (
                      <div>
                        <NGLabel>{fileInfo.label}</NGLabel>
                        <NGButton
                          className="mt-2"
                          variant="primary"
                          onClick={() => setCodeEditorOpenProperty(fileProperty)}
                        >
                          Set Data
                        </NGButton>
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          )
        ) : null}

        <div className="mt-4 flex items-center">
          <NGButton
            style={{ width: 220, marginRight: 10 }}
            disabled={isLoadingInstall}
            variant={"danger"}
            onClick={() => navigate(`${basePath}/template`)}
          >
            Cancel
          </NGButton>
          <NGButton
            style={{ width: 220, marginRight: 10 }}
            disabled={(!filesAreDirty && values === defaultValues) || isLoadingInstall}
            variant={"danger"}
            outlined
            onClick={() => {
              filesDispatch({ type: FilesReducerActionType.RESET_TO_DEFAULTS, payload: { content: "", property: "" } });
              setValues(defaultValues);
            }}
          >
            Reset
          </NGButton>
          <NGButton
            style={{ width: 220, marginRight: 10 }}
            // TODO we can have a way of validating the values
            disabled={isLoadingInstall || (!paramName && !nameRef.current.isValid)}
            loading={isLoadingInstall}
            variant={"primary"}
            onClick={() => handleTemplate()}
          >
            Template
          </NGButton>
          <NGButton
            style={{ width: 220 }}
            disabled={isLoadingInstall || (!paramName && !nameRef.current.isValid)}
            loading={isLoadingInstall}
            variant={"action"}
            onClick={() => handleInstall()}
          >
            Install App
          </NGButton>
        </div>
      </div>
      {!!codeEditorOpenProperty ? (
        <CodeEditorModal
          title={templateVersion ? templateVersion.files![codeEditorOpenProperty].label : "File"}
          initialValue={filesState.current[codeEditorOpenProperty] || ""}
          open={!!codeEditorOpenProperty}
          onOk={(value) => {
            filesDispatch({
              type: FilesReducerActionType.SET_FILE,
              payload: { content: value, property: codeEditorOpenProperty },
            });
            setCodeEditorOpenProperty("");
          }}
          onClose={() => setCodeEditorOpenProperty("")}
        />
      ) : null}
      {isTemplateView ? (
        <Modal
          open={isTemplateView}
          width={"90vw"}
          styles={{ body: { height: "70vh", padding: 0 } }}
          onCancel={() => setIsTemplateView(false)}
          maskClosable={false}
          closable={false}
          destroyOnClose={true}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" onClick={() => setIsTemplateView(false)} disabled={isLoadingInstall}>
                Close
              </NGButton>
              <NGButton
                variant="primary"
                onClick={() => handleInstall()}
                loading={isLoadingInstall}
                disabled={isLoadingInstall}
              >
                Install
              </NGButton>
            </div>
          }
        >
          <div className="h-full overflow-auto p-1">
            <div className="flex items-center gap-2 mb-4">
              <NGLabel>{helmTemplates.length} Items</NGLabel>
              <div className="flex-grow" />
              <NGButton
                onClick={() => {
                  navigator.clipboard.writeText(jsYaml.dump(helmTemplates, { sortKeys: k8sKeySort }));
                  notification.success({ message: "Copied all to clipboard" });
                }}
                variant="secondary"
                size={"small"}
                renderIcon={(_, props) => <Copy {...props} />}
              >
                Copy
              </NGButton>
              <NGButton
                onClick={() => {
                  notification.success({ message: "Download initiated" });
                  const blob = jsYaml.dump(helmTemplates, { sortKeys: k8sKeySort });
                  const file = new Blob([blob], { type: `text/yaml` });
                  const href = URL.createObjectURL(file);
                  const a = document.createElement("a");
                  a.style.display = "none";
                  a.classList.add("cpln-temp-a");
                  a.download = `marketplace-${templateName}-items.yaml`;
                  a.href = href;
                  a.click();
                }}
                variant="secondary"
                size={"small"}
                renderIcon={(_, props) => <Download {...props} />}
              >
                Download
              </NGButton>
            </div>
            <CodeEditor
              language="yaml"
              options={{
                readOnly: true,
                style: {
                  height: "calc(100% - 50px)",
                  overflow: "auto",
                },
                className: "text-sm",
              }}
              value={jsYaml.dump(helmTemplates, { sortKeys: k8sKeySort })}
              setValue={() => {}}
              minHeight="calc(100% - 50px)"
            />
          </div>
        </Modal>
      ) : null}
    </NGFormContext.Provider>
  );
};

export const MarketplaceTemplateInstall = observer(MarketplaceTemplateInstallRaw);
